import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BASE_URL } from "../../app/utils/apis/base";
import { ApiConstants } from "../../app/utils/apis";
import { apiClient } from "../../app/helper/axiosHelper";

// Async thunk for adding & fetching stores 
export const addStoreAd = createAsyncThunk('stores/addStoreAd', async (shopData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.ADDSTORESADD}`, shopData);
        return response.data;

    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

export const getStoresAd = createAsyncThunk('stores/getStoresAd', async ({ page = 1, pageSize = 10 } = {}, { getState, rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}${ApiConstants.GETSTOREADD}`, {
            params: {
                page,
                pageSize
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

export const updateStoreStatus = createAsyncThunk('opsTeam/updateStoreStatus', async ({ storeId, status, reject_reason }, { dispatch, rejectWithValue }) => {
    try {
        const response = await apiClient.put(`${BASE_URL}${ApiConstants.UPDATESTORESTATUS}`, { store_id: storeId, status, reject_reason });
        dispatch(getStoresAd({ page: 1, pageSize: 10 }));
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
})

// Slices for stores
const StoreSlice = createSlice({
    name: 'stores',
    initialState: {
        stores: [],
        status: 'idle',
        error: null,
        pagination: {
            totalData: 0,
            currentPage: 1,
            pageSize: 10,
            totalPages: 1
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addStoreAd.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(addStoreAd.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.stores = [...state.stores, action.payload.data.stores];
            })
            .addCase(addStoreAd.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';
            })
            .addCase(getStoresAd.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getStoresAd.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.stores = action.payload.data.stores;
                state.pagination = action.payload.data.pagination;
            })
            .addCase(getStoresAd.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';
            })
            .addCase(updateStoreStatus.fulfilled, (state, action) => {
                const updatedAd = action.payload.data.stores;
                const updatedAdIndex = state.stores.findIndex(store => store?.id === updatedAd?.stores?.id);
                if (updatedAdIndex !== -1) {
                    state.stores[updatedAdIndex] = action.payload.data;
                }
            })
            .addCase(updateStoreStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';
            })
    }
})

// Selectors for accessing state
export const selectAllStore = (state) => state.stores.stores;
export const selectStoreStatus = (state) => state.stores.status;
export const selectStoreError = (state) => state.stores.error;
export const selectStorePagination = (state) => state.stores.pagination;

export default StoreSlice.reducer