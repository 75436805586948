import React from 'react';
import Loader from '../../../atoms/Loader/Loader';
import { Switch, } from 'antd';
import { image } from '../../../../app/utils/common/image';

const CouponContainer = ({ offers, loading, onPressSwitchItem, onEditCoupon }) => {
    return (
        <>
            <div>
                <table className='table-default w-full'>
                    <thead>
                        <tr>
                            <th className='text-start'>Created Date</th>
                            <th>Coupon Code</th>
                            <th>Discount Type</th>
                            <th>Discount Value</th>
                            <th>Expiration Date</th>
                            <th>Terms & Condition</th>
                            <th>Disabled</th>
                            <th>Status</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ?
                            <tr>
                                <td colSpan="3"> <Loader height='h-[20rem]' />
                                </td>
                            </tr>
                            : <div className='p-[12px] contents '>
                                {offers.map((offer) => {
                                    const createdAt = offer?.createdAt ? new Date(offer.createdAt).toISOString().split('T')[0] : 'Invalid Date';
                                    const endDate = offer?.end_date ? new Date(offer.end_date).toISOString().split('T')[0] : 'Invalid Date';
                                    return (
                                        <tr className='m-[12px] [border-bottom:_.8px_solid_#e2e9e9]'>
                                            <td className='text-start capitalize'>{createdAt}</td>
                                            <td>{offer?.code}</td>
                                            <td>{offer?.discount_type}</td>
                                            <td>{offer?.discount_amt}</td>
                                            <td>{endDate}</td>
                                            <td>{offer?.terms}</td>
                                            <td>
                                                <Switch
                                                    checked={offer?.is_active === 0}
                                                    onClick={() => onPressSwitchItem(offer)}
                                                    disabled={offer?.disabled}
                                                />
                                            </td>
                                            <td className='flex justify-center items-center' >
                                                <div className={`flex justify-center items-center Success-bg  ${offer.status === 'upcoming' ? 'bg-[#f59e0b]' : offer.status === 'active' ? 'bg-[#10b981]' : 'bg-[#ef4444]'}`}> {offer.status}</div>
                                            </td>
                                            <td className='cursor-pointer' onClick={() => onEditCoupon(offer)}>
                                                <img className='cursor-pointer w-[25px]' src={image.IMG_EDIT} />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </div>
                        } </tbody>
                </table>
            </div>
        </>
    )
}

export default CouponContainer
