export const ApiConstants = {
    SENDOTP: '/api/auth/login/sendotp',
    LOGIN: '/api/auth/login',
    RESENDOTP: '/api/auth/resendotp',
    REFRESHTOKEN: '/api/auth/refresh-accesstoken',
    LOGOUT:'/api/auth/logout',
    ALLUSERS: '/api/admin/all-users',
    ADREQUEST: '/api/admin/ad-requests',
    UPDATEADSTATUS: '/api/admin/update-ad-status',
    ADDTEMPLATE: '/api/admin/add-template',
    UPLOADFILE: '/api/upload/',
    GETTEMPLATE: '/api/admin/templates',
    ALLTRANSACTIONS: '/api/admin/all/transactions',
    CREATEOFFERS: '/api/admin/create-offer',
    GETOFFERS: '/api/common/offers',
    UPDATEOFFERSTATUS: '/api/admin/update/offer',
    ADDCATEGORY: '/api/admin/categories/add',
    GETCATEGORIES: '/api/common/categories',
    ADDSLOTS: '/api/admin/slots/add',
    GETSLOTS: '/api/common/slots',
    ADDOPSTEAM: '/api/admin/ops/members/add',
    GETOPSTEAM: '/api/admin/ops/members',
    UPDATEOPSTEAM: '/api/admin/ops/members/update',
    ADDSTORESADD: '/api/admin/ops/assign-request',
    GETSTOREADD: '/api/common/ops/stores',
    UPDATESTORESTATUS:'/api/admin/store/update-status',
    GETAGENCYREQUEST:'/api/admin/agency-requests',
    UPDATEAGENCYSTATUS:'/api/admin/agency-requests/update-status'
}