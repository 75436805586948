import React from 'react';
import './SearchBar.scss'

const SearchBar = ({ placeholder, name, type, id, value, onChange }) => {
    return (
        <div className='w-full'>
            <input
                className="input input-md h-11 focus:ring-sky-900 focus-within:ring-sky-900 focus-within:border-sky-900 focus:border-sky-900"
                style={{ padding: "0rem 2.25rem" }}
                placeholder={placeholder}
                name={name}
                type={type}
                id={id}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onWheel={(e) => e.target.blur()}
            />
        </div>
    )
}

export default SearchBar
