import React from 'react';
import './Loader.scss'

const Loader = ({ height, paddingRight }) => {
    return (
        <div className={`${height} ${paddingRight} w-full text-center flex items-center justify-center`}>
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loader
