import { TimePicker } from 'antd';
import React, { useState } from 'react';
import './SlotContainer.scss'
import Button from '../../../atoms/Button/Button';
import { image } from '../../../../app/utils/common/image';
import Input from '../../../atoms/Input/Input';
import moment from 'moment';
import SaveModal from '../../../atoms/save-modal/save-modal';
import Loader from '../../../atoms/Loader/Loader';

const SlotContainer = ({ errors, slots, handleSlotsSave, handleInputChange, handleRemoveSlots, loading }) => {
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState(null);
    const confirmDelete = (index, slotId) => {
        setDeleteIndex({ index, slotId });
        setDeleteModal(true);
    };

    const handleDelete = () => {
        if (deleteIndex !== null) {
            handleRemoveSlots(deleteIndex.index, deleteIndex.slotId);
            setDeleteModal(false);
            setDeleteIndex(null);
        }
    };
    return (
        <div className='card-border h-[100vh] pb-[150px] '>
            <div className='flex justify-end py-[20px] pr-[30px]  [border-bottom:_1px_solid_#e2e9e9]'>
                <Button buttonTitle='Save' background={'bg-[#10b981]'} onClick={() => { handleSlotsSave() }} />
            </div>
            {!loading ? <div className='overflow-auto  max-h-[700px]'>
                {slots.map((slot, index) => {
                    return (
                        <> <div className='flex gap-8 items-center justify-between p-[2rem] [border-bottom:_1px_solid_#e2e9e9] '>
                            <div className='flex items-center'>
                                <div className='text-2xl font-[600] text-[--themeColor]'>Slot {index + 1}</div>
                                <div className='flex gap-10 ml-[5rem]'>
                                    <div>
                                        <div className='form-label'>From *</div>
                                        <TimePicker
                                            value={slot.start_time ? moment(slot.start_time, 'HH:mm A') : null}
                                            // use24Hours
                                            format={'hh:mm'}
                                            placeholder="9:00 AM"
                                            onChange={(time) => handleInputChange(index, 'start_time', time)}
                                            showNow={true}
                                            className="timepicker"
                                            minuteStep={15}
                                            disabled={!slot.isNew}
                                        />
                                        {errors[index]?.start_time && <div className='text-red-500'>{errors[index]?.start_time}</div>}
                                    </div>
                                    <div>
                                        <div className='form-label'>To *</div>
                                        <TimePicker
                                            value={slot.end_time ? moment(slot.end_time, 'HH:mm A') : null}
                                            // use12Hours
                                            format={'hh:mm'}
                                            placeholder="12:00 AM"
                                            onChange={(time) => handleInputChange(index, 'end_time', time)}
                                            showNow={true}
                                            className="timepicker"
                                            minuteStep={15}
                                            disabled={!slot.isNew}
                                        />
                                        {errors[index]?.end_time && <div className='text-red-500'>{errors[index]?.end_time}</div>}
                                    </div>
                                    <div>
                                        <Input
                                            label="Price *"
                                            placeholder="Enter price"
                                            type="any"
                                            id={`price-${index}`}
                                            name="price"
                                            value={slot.price || ''}
                                            onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                            disabled={!slot.isNew}
                                        />
                                        {errors[index]?.price && <div className='text-red-500'>{errors[index]?.price}</div>}
                                    </div>
                                </div>
                            </div>
                            <img onClick={() => confirmDelete(index, slot.id)} className='cursor-pointer w-[20px]' src={image.IMG_RED_DELETE} />

                        </div>
                            {deleteModal && <SaveModal
                                text="Are you sure you want to delete this category?"
                                deleteModal={deleteModal}
                                setDeleteModal={setDeleteModal}
                                buttons={['Yes', 'No']}
                                onPressPositive={handleDelete}
                                onPressNegative={() => { setDeleteModal(false); setDeleteIndex(null) }}
                            />}
                        </>
                    )
                })}
            </div>
                : <div className='max-h-[450px] flex items-center justify-center h-[450px]'> <Loader /></div>}
        </div>
    )
}

export default SlotContainer
