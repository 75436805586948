import React, { useState } from 'react';
import Loader from '../../../atoms/Loader/Loader';
import { useDispatch } from 'react-redux';
import { updateAgencyStatus } from '../../../../redux/Slices/agencySlice';
import Button from '../../../atoms/Button/Button';
import OutsideClickHandler from 'react-outside-click-handler';
import AdStatus from '../../../atoms/reserv-status/ad-status';
import RejectModal from '../../../atoms/reject-modal/reject-modal';
import { image } from '../../../../app/utils/common/image';

const AllAgecniesContainer = ({ agencies, loading, setLoading }) => {
    const [showStatus, setShowStatus] = useState(false)
    const [selectedAgencyId, setSelectedAgencyId] = useState(null);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const [fullScreen, setFullScreen] = useState(false);
    const dispatch = useDispatch();

    const handleShowStatus = (index) => {
        setShowStatus(showStatus === index ? null : index);
    }

    const handleClose = () => {
        setShowStatus(false)
    }

    const handleAcceptAgency = (agencyId) => {
        dispatch(updateAgencyStatus({ agencyId, status: 'APPROVE' }));
        setShowStatus(false)
    }

    const handleRejectAgency = (agencyId) => {
        setSelectedAgencyId(agencyId);
        setShowRejectModal(true);
        setShowStatus(false)
    }

    // Handle to Reject the Agency on Click of Save button
    const handleRejectedSaveBtn = () => {
        setLoading(true)
        if (selectedAgencyId && rejectReason) {
            dispatch(updateAgencyStatus({ agencyId: selectedAgencyId, status: 'REJECT', reject_reason: rejectReason }));
            setShowRejectModal(false);
            setRejectReason('');
            setSelectedAgencyId(null);
            setShowStatus(false)
        }
        setLoading(false)
    };

    // Open Full screen 
    const openFullscreen = (id) => {
        const elem = document.getElementById(`adImage_${id}`);
        if (elem) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
                setFullScreen(true);
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
                setFullScreen(true);
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
                setFullScreen(true);
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
                setFullScreen(true);
            }
        } else {
            console.error(`Element with id adImage_${id} not found`);
        }
    };

    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            setFullScreen(false);
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
            setFullScreen(false);
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
            setFullScreen(false);
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
            setFullScreen(false);
        }
    };
    return (
        <>
            <div className='h-[740px] overflow-auto'>
                <table className='table-default w-full'>
                    <thead>
                        <tr>
                            <th className='text-start'>Date</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Pin Code</th>
                            <th>Phone Number</th>
                            <th>Gst Number</th>
                            <th>Registration Proof</th>
                            <th>Gst Certificate</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ?
                            <tr>
                                <td colSpan="3"> <Loader height='h-[20rem]' />
                                </td>
                            </tr>
                            : <div className='p-[12px] contents '>
                                {agencies.map((agency, index) => {
                                    const createdAt = new Date(agency.createdAt).toISOString().split('T')[0];
                                    return (
                                        <tr className='m-[12px] [border-bottom:_.8px_solid_#e2e9e9]'>
                                            <td className='text-start capitalize'>{createdAt}</td>
                                            <td>{agency.name}</td>
                                            <td>{agency.address}, {agency.city}, {agency.state}</td>
                                            <td>{agency.pin_code}</td>
                                            <td>{agency.phone_no}</td>
                                            <td className='flex items-center justify-center'>
                                                <img src={agency.registration_proof ? agency.registration_proof : image.DefaultImage} className='w-[4rem] h-[4rem] '
                                                    id={`adImage_${agency.id}`}
                                                    onClick={() => fullScreen ? exitFullScreen() : openFullscreen(agency.id)} />
                                            </td>
                                            <td>{agency.gst_no}</td>

                                                <td className='flex items-center justify-center'>
                                                    <img src={agency.gst_certificate ? agency.gst_certificate : image.DefaultImage} className='w-[4rem] h-[4rem] '
                                                        id={`adImage_${agency.id}`}
                                                        onClick={() => fullScreen ? exitFullScreen() : openFullscreen(agency.id)} />
                                                </td>
                                                <td>
                                                    <div className='flex items-center justify-center'>{agency.admin_approval_status === 'APPROVE' ? (
                                                        <Button buttonTitle={'Accepted'} background='bg-[#228B22]' className='cursor-not-allowed' disabled onClick={() => { }} />
                                                    ) : agency.admin_approval_status === 'REJECT' ? (
                                                        <Button buttonTitle={'Rejected'} background='bg-[#8b0000]' disabled className='cursor-not-allowed' onClick={() => { }} />
                                                    ) : (
                                                        <>
                                                            <Button buttonTitle={'Pending'} background='bg-[#f59e0b]' onClick={() => { handleShowStatus(index) }} />
                                                            {showStatus === index && (
                                                                <OutsideClickHandler onOutsideClick={handleClose}>
                                                                    <AdStatus handleRejectAds={() => handleRejectAgency(agency.id)} handleAcceptAds={() => handleAcceptAgency(agency.id)} agency={agency} />
                                                                </OutsideClickHandler>
                                                            )}
                                                        </>
                                                    )} </div></td>
                                        </tr>
                                    )
                                })}
                            </div>
                        } </tbody>
                </table>
                <RejectModal showRejectModal={showRejectModal} setShowRejectModal={setShowRejectModal} handleRejectedSaveBtn={handleRejectedSaveBtn} rejectReason={rejectReason} setRejectReason={setRejectReason} />

            </div>
        </>
    )
}

export default AllAgecniesContainer
