import React from "react";
import { Modal } from "antd";
import { image } from "../../../app/utils/common/image";
import './reject-modal.scss';


const RejectModal = (props) => {
    const { showRejectModal, setShowRejectModal, rejectReason, setRejectReason, handleRejectedSaveBtn } = props
    const handleDeniedModalClose = () => {
        setShowRejectModal(false);
        setRejectReason('');
    }

    return (
        <div>
            <Modal
                className="save-modal"
                open={showRejectModal}
                footer={null}
                closeIcon={false}
                bodyProps={null}
                onCancel={() => setShowRejectModal(false)}
            >
                <div className="denied-container">
                    <div className="modal-denied-image-header">
                        <div>
                            <h2>Oh no! 😕  </h2>
                            <div className="denied-text">Why are you rejecting this advertisment?</div>
                        </div>

                        <button onClick={handleDeniedModalClose} className="btn-modal close-button">
                            <img alt="#" src={image.IMG_CLOSE} className="image_close" />
                        </button>
                    </div>

                    <div className="text-area text-area-denied">
                        <textarea className="textarea"
                            id={''}
                            rows={10}
                            placeholder='Write a reject reason'
                            value={rejectReason}
                            onChange={(e) => { setRejectReason(e.target.value) }}
                        />
                    </div>
                    <div></div>
                    <div className="reserv-continue-btn denied-btns">
                        <button className="btns denied-btn" onClick={handleDeniedModalClose}>Cancel</button>
                        <button className="btns denied-changes" onClick={handleRejectedSaveBtn}>Submit</button>
                    </div>

                </div>
            </Modal></div>
    )
}

export default RejectModal;