import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BASE_URL } from "../../app/utils/apis/base";
import { ApiConstants } from "../../app/utils/apis";
import { apiClient } from "../../app/helper/axiosHelper";

// Async thunk for fetching & updating ads-Request
export const getAdRequests = createAsyncThunk('ads/getAdRequests', async ({ search, page = 1, pageSize = 10 }, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}${ApiConstants.ADREQUEST}`, {
            params: {
                search,
                page,
                pageSize
            },
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

export const updateAdStatus = createAsyncThunk('ads/updateAdStatus', async ({ adId, status, reject_reason }, { dispatch, rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.UPDATEADSTATUS}`, { ad_id: adId, status, reject_reason });
        dispatch(getAdRequests({ search: '', page: 1, pageSize: 10 }));
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
})

// Slices for ads-request
const adRequestSlice = createSlice({
    name: 'ads',
    initialState: {
        searchResult: [],
        ads: [],
        status: 'idle',
        error: null,
        pagination: {
            totalData: 0,
            currentPage: 1,
            pageSize: 10,
            totalPages: 1,
        }
    },
    reducers: {
        setSearchResult(state, action) {
            state.searchResult = action.payload;
        },
        clearAdSearchResult(state) {
            state.searchResult = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAdRequests.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getAdRequests.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.ads = action.payload.data.ads;
                state.pagination = action.payload.data.pagination;
            })
            .addCase(getAdRequests.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';
            })
            .addCase(updateAdStatus.fulfilled, (state, action) => {
                // Update the ad status in the state after successful update
                const updatedAd = action.payload.data;
                const updatedAdIndex = state.ads.findIndex(ad => ad?.id === updatedAd?.ads?.id);
                if (updatedAdIndex !== -1) {
                    state.ads[updatedAdIndex] = action.payload.data;
                }
            })
    }
})

export const { setSearchResult, clearAdSearchResult } = adRequestSlice.actions;

// Selectors for accessing state
export const selectAllAds = (state) => state.ads.ads;
export const selectAdPagination = (state) => state.ads.pagination;
export const selectSearchResult = (state) => state.ads.searchResult;
export const selectAdStatus = (state) => state.ads.status;
export const selectAdError = (state) => state.ads.error;

export default adRequestSlice.reducer