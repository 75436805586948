import React from 'react'

const Button = ({ buttonTitle, onClick, background, disabled }) => {
    return (
        <button className={`button ${background} ${disabled} text-white flex items-center rounded-[.375rem] gap-2 px-8 py-2 text-md cursor-pointer `} onClick={() => { onClick() }}>
            {/* <img src={image} alt='add' /> */}
            <span>{buttonTitle}</span>
        </button>
    )
}

export default Button
