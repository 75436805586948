import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getTransactions, selectAllTransactions, selectTransactionPagination, selectTransactionStatus } from '../../../redux/Slices/transactions';
import Pagination from '../../atoms/Pagination/Pagination';
import Loader from '../../atoms/Loader/Loader';
import PageContainerHeader from '../PageContainerHeader/PageContainerHeader'
import AllTransactionsContainer from './AllTransactionContainer/AllTransactionContainer'

const AllTransactions = () => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);

    const dispatch = useDispatch();
    const transactions = useSelector(selectAllTransactions);
    const pagination = useSelector(selectTransactionPagination);
    const transcStatus = useSelector(selectTransactionStatus)

    useEffect(() => {
        dispatch(getTransactions({ page: currentPage, pageSize })); // Dispatch Get Transaction action
    }, [dispatch, currentPage, pageSize])

    // Transaction status loading state
    useEffect(() => {
        if (transcStatus === 'loading') {
            setLoading(true);
        } else if (transcStatus === 'succeeded') {
            setLoading(false);
        }
    }, [transcStatus]);

    const pageNumbers = [];
    for (let i = 1; i <= pagination?.totalPages; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    }
    return (
        <div>
            <PageContainerHeader title={'All Transactions'} />
            {!loading ? <><AllTransactionsContainer transactions={transactions} />
                <Pagination
                    pageNumbers={pageNumbers}
                    handlePageLink={handlePageChange}
                    totalProfile={pagination.totalData}
                    prevPage={() => handlePageChange(pagination.currentPage - 1)}
                    nextPage={() => handlePageChange(pagination.currentPage + 1)}
                    activePage={pagination.currentPage}
                /></> : <div className='h-[26rem] flex justify-center items-center'> <Loader /></div>}
        </div>
    )
}

export default AllTransactions
