import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { clearSearchResult, getUsers, selectAllUsers, selectUserPagination } from '../../../../redux/Slices/usersSlice';
import UsersListContainer from '../UsersListContainer/UsersListContainer';
import PageContainerHeader from '../../PageContainerHeader/PageContainerHeader';

const UsersList = () => {
    const [loading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);

    const dispatch = useDispatch();
    const users = useSelector(selectAllUsers);
    const pagination = useSelector(selectUserPagination);
    const userStatus = useSelector((state) => state.users.status);

    useEffect(() => {
        if (searchString) {
            dispatch(getUsers({ search: searchString, page: currentPage, pageSize })); // Dispatch the getusers action
        } else {
            dispatch(getUsers({ page: currentPage, pageSize }));
        }
    }, [dispatch, searchString, currentPage, pageSize]);

    // userStatus loading
    useEffect(() => {
        if (userStatus === 'loading') {
            setLoading(true);
        } else if (userStatus === 'succeeded') {
            setLoading(false);
        }
    }, [userStatus]);

    // Search By Name 
    const onChangeSearch = (search = '') => {
        setSearchString(search);
        setCurrentPage(1);
    }

    const onPressCancelSearch = () => {
        setSearchString('');
        setCurrentPage(1);
        dispatch(clearSearchResult()); // Clear search results in Redux state
    }

    // Pagination
    const pageNumbers = [];
    for (let i = 1; i <= pagination?.totalPages; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    return (
        <div>
            <PageContainerHeader title='All Users' />
            <UsersListContainer
                usersData={users}
                loading={loading}
                pageNumbers={pageNumbers}
                totalProfile={pagination.totalData}
                handlePageChange={handlePageChange}
                prevPage={() => handlePageChange(pagination.currentPage - 1)}
                nextPage={() => handlePageChange(pagination.currentPage + 1)}
                activePage={pagination.currentPage}
                onChangeSearch={onChangeSearch}
                onPressCancelSearch={onPressCancelSearch}
                searchString={searchString}
            />
        </div>
    );
}

export default UsersList;