import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BASE_URL } from "../../app/utils/apis/base";
import { ApiConstants } from "../../app/utils/apis";
import { apiClient } from "../../app/helper/axiosHelper";

// Async thunk for fetching offers
export const createOffer = createAsyncThunk('offers/createOffer', async (offerData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.CREATEOFFERS}`, offerData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

export const getOffers = createAsyncThunk('offers/getOffers', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}${ApiConstants.GETOFFERS}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
})

export const toogleOfferStatus = createAsyncThunk('offers/toogleOfferStatus', async ({ id, is_active, start_date, end_date }, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.UPDATEOFFERSTATUS}`, { offer_id: id, is_active, start_date, end_date });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
})

// Slice for offers
const offerSlice = createSlice({
    name: 'offers',
    initialState: {
        offers: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createOffer.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(createOffer.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.offers = [...state.offers, action.payload.data.offer];
            })
            .addCase(createOffer.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';
            })
            .addCase(getOffers.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getOffers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.offers = action.payload.data.offers;
            })
            .addCase(getOffers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';
            })
            .addCase(toogleOfferStatus.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(toogleOfferStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedOffer = action.payload.data?.offers;
                if (updatedOffer) {
                    state.offers = state.offers.map(offer =>
                        offer.id === updatedOffer.id ? updatedOffer : offer
                    );
                } else {
                    state.error = 'Invalid offer data received';
                }
            })
            .addCase(toogleOfferStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';
            });
    }
})

// Selectors for accessing state
export const selectAllOffers = (state) => state.offers.offers;
export const selectOfferStatus = (state) => state.offers.status;
export const selectOfferError = (state) => state.offers.error;

export default offerSlice.reducer