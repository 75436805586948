import React from 'react'
import { image } from '../../../app/utils/common/image';
import Button from '../../atoms/Button/Button';

const PageContainerHeader = ({ title, button, buttonTitle, onclickButton, button1, buttonTitle1, onclickButton2 }) => {
    return (
        <div className='card-border card-body mb-4'>
            <div className='flex items-center justify-between'>
                <div className='text-xl font-semibold text-[--themeColor]'>{title}</div>
                <div className='flex gap-2'>
                    {button1 && <Button buttonTitle={buttonTitle1} onClick={onclickButton2} image={image.IMG_PLUSICON} background={'bg-[--themeColor]'} />}
                    {button && <Button buttonTitle={buttonTitle} onClick={onclickButton} image={image.IMG_PLUSICON} background={'bg-[--themeColor]'} />}
                </div>
            </div>
        </div>
    )
}

export default PageContainerHeader
