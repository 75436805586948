import React from 'react'
import SEARCHICON from '../../../../assets/images/svg/searchIcon.svg'
import CROSSICON from '../../../../assets/images/svg/CrossGrey.svg'
import SearchBar from '../../../atoms/SearchBar/SearchBar';

const AdvertismentSearch = (props) => {
    const { onChangeSearch, onPressCancelSearch, searchString, placeholder } = props
    return (
        <div className='grid pb-[24px]'>
            <div className='col-end-7 col-span-2'>
                <div className="search-input flex items-center relative">
                    <div className='absolute left-5'>
                        <img src={SEARCHICON} alt='search-icon' />
                    </div>
                    <SearchBar
                        placeholder={placeholder}
                        type='text'
                        id="search-bar"
                        name="search-bar"
                        value={searchString}
                        onChange={onChangeSearch}
                    />
                    {searchString.length >= 1 && (
                        <button className='absolute right-5' onClick={onPressCancelSearch}>
                            <img src={CROSSICON} alt='cross' />
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AdvertismentSearch