import React from 'react';
import PREVICON from '../../../assets/images/svg/prev.svg'
import NEXTICON from '../../../assets/images/svg/next.svg'
import './Pagination.scss'

const Pagination = (props) => {
    const { pageNumbers, handlePageLink, prevPage, nextPage, totalProfile, activePage } = props
    return (
        <div className='flex gap-6 items-center m-4 mt-6 p-2'>
            <div className='text-[--themeColor] font-[600] text-base'>Total {totalProfile}</div>
            <nav className='list-disabled'>
                <ul className='flex justify-center items-center'>
                    {activePage > 1 ? <li onClick={prevPage} className='pagination-paper mr-2'><img src={PREVICON} alt='prev' /></li> : <li onClick={() => { }} className='pagination-paper pagination-paper-disabled mr-2 cursor-not-allowed' disabled ><img src={PREVICON} alt='prev' /></li>}
                    {pageNumbers.map(number => {
                        return (
                            <button key={number} onClick={() => handlePageLink(number)} className={`pagination-paper cursor-pointer text-sky-900 ${activePage === number ? 'pagination-paper-inactive' : ''}`}>{number}</button>
                        )
                    })}
                    {activePage < totalProfile ? <li onClick={nextPage} className='pagination-paper ml-2' disabled={activePage >= totalProfile} ><img src={NEXTICON} alt='prev' /></li> : <li onClick={() => { }} className='pagination-paper pagination-paper-disabled ml-2 cursor-not-allowed' disabled ><img src={NEXTICON} alt='prev' /></li>}
                </ul>
            </nav>
        </div>
    )
}

export default Pagination
