import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addTemplates, deleteTemplate, getTemplate, selectAllTemplates, selectTemplatePagination, selectTemplateStatus, uploadFile } from '../../../../redux/Slices/addTemplateSlice'
import EmptyTemplate from '../../../atoms/EmptyTemplate/EmptyTemplate'
import Button from '../../../atoms/Button/Button'
import Pagination from '../../../atoms/Pagination/Pagination'
import Loader from '../../../atoms/Loader/Loader'
import PageContainerHeader from '../../PageContainerHeader/PageContainerHeader'
import TemplateList from '../TemplateList/TemplateList'

const MAX_FILE_SIZE = 3000000;
const MIN_FILE_SIZE = 1;

const TemplateContainer = () => {
    const [addTemplate, setAddTemplate] = useState([])
    const [showCount, setShowCount] = useState(1);
    const [showEdit, setShowEdit] = useState(false);
    const [showEditDelete, setShowEditDelete] = useState(false)
    const [errorModal, setErrorModal] = useState(false);
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);

    const dispatch = useDispatch()
    const templates  = useSelector(selectAllTemplates);
    const pagination = useSelector(selectTemplatePagination);
    const templateStatus = useSelector(selectTemplateStatus)

    // Handle to Template stauts loading state
    useEffect(() => {
        if (templateStatus === 'loading') {
            setLoading(true);
        } else if (templateStatus === 'succeeded') {
            setLoading(false);
        }
    }, [templateStatus]);


    useEffect(() => {
        dispatch(getTemplate({ page: currentPage, pageSize })); //Dispatch Get Templates action
    }, [dispatch, currentPage, pageSize]);


    // Handle to Add Template
    const handleAddTemplate = () => {
        setAddTemplate([...addTemplate, { template_url: '', template_name: '' }]);
        setShowCount(showCount + 1);
        setShowEdit(false)
    };

    // Handle to Remove Template
    const handleRemoveTemplate = (index, templateId) => {
        setLoading(true);
        if (templateId) {
            dispatch(deleteTemplate(templateId)).unwrap()
                .then(() => {
                    dispatch(getTemplate({ page: currentPage, pageSize })).unwrap();
                });
        } else {
            const temp = [...addTemplate];
            temp.splice(index, 1);
            setAddTemplate(temp);
            if (showCount > 3) {
                setShowCount(showCount - 1);
            }
            setShowEdit(false);
        }
        setShowEdit(false);
        setShowEditDelete(false);
        setLoading(false);
    };

    // Handle to Upload image 
    const handleUploadImg = async (imageList, index) => {
        setLoading(true)
        const selectedImage = imageList[0].file;

        if (selectedImage.size < MAX_FILE_SIZE && selectedImage.size > MIN_FILE_SIZE) {
            try {
                const formData = new FormData();
                formData.append('file', selectedImage);

                // Dispatch uploadFile action
                const uploadResponse = await dispatch(uploadFile(formData)).unwrap();
                const url = uploadResponse;
                const tempTemplates = [...addTemplate];
                tempTemplates[index] = {
                    url: url,
                    type: selectedImage.type,
                    name: selectedImage.name,
                };

                setShowEdit(false);
                setAddTemplate(tempTemplates);

            } catch (error) {
                console.error('Error uploading image:', error);
                setErrorModal(true);
            }
        } else {
            setErrorModal(true);
        }
        setLoading(false)
    };

    // Handle to Save templates
    const handleSaveTemplates = async () => {
        setLoading(true);
        try {
            const savedTemplates = [];
            for (const template of addTemplate) {
                if (template.url && template.name) {
                    const savedTemplate = await dispatch(addTemplates({ template_url: template.url, template_name: template.name })).unwrap();
                    savedTemplates.push(savedTemplate);
                }
            }
            await dispatch(getTemplate({ page: currentPage, pageSize })).unwrap(); // Dispatch GetTemplate action
            setAddTemplate([]);
            dispatch(getTemplate({ page: currentPage, pageSize }));

        } catch (error) {
            console.error('Error saving templates:', error);
            setErrorModal(true);
        } finally {
            setLoading(false);
        }
    };

    const pageNumbers = [];
    for (let i = 1; i <= pagination?.totalPages; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    }


    return (
        <div>
            <PageContainerHeader title='Add Templates' button buttonTitle='Add Template' onclickButton={handleAddTemplate} />
            {!loading ? <div className='card-border h-[100%] pb-[150px]'>
                {templates?.length === 0 ? (
                    <EmptyTemplate line1='Oops! Something got scrambled.' line2='Add a new template' />
                ) : (
                    <>
                        <div className='flex justify-end py-[20px] pr-[30px] [border-bottom:_1px_solid_#e2e9e9]'>
                            <Button buttonTitle='Save' background={'bg-[#10b981]'} onClick={() => { handleSaveTemplates() }} />
                        </div>
                        <TemplateList
                            templates={templates}
                            addTemplate={addTemplate}
                            handleRemoveTemplate={handleRemoveTemplate}
                            handleAddTemplate={handleAddTemplate}
                            handleUploadImg={handleUploadImg}
                            showEdit={showEdit}
                            setShowEdit={setShowEdit}
                            errorModal={errorModal}
                            setErrorModal={setErrorModal}
                            loading={loading}
                            showEditDelete={showEditDelete}
                            setShowEditDelete={setShowEditDelete}
                        />
                        <Pagination
                            pageNumbers={pageNumbers}
                            handlePageLink={handlePageChange}
                            totalProfile={pagination.totalData}
                            prevPage={() => handlePageChange(pagination.currentPage - 1)}
                            nextPage={() => handlePageChange(pagination.currentPage + 1)}
                            activePage={pagination.currentPage}
                        />
                    </>
                )}

            </div>
                : <div className='max-h-[450px] flex items-center justify-center h-[450px]'>
                    <Loader />
                </div>}
        </div>
    )
}

export default TemplateContainer
