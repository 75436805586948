import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { sendOtp, verifyOtp } from '../../../redux/Slices/authSlice';
import { image } from '../../../app/utils/common/image';
import Input from '../../atoms/Input/Input';
import VerifyLoginOTP from '../../molecules/VerifyLoginOtp/VerifyLoginOtp';
import Loader from '../../atoms/Loader/Loader';
import './LoginPage.scss';

const LoginPage = () => {
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('+91');
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [activeButton, setActiveButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);

    // Handle to send otp
    const handleSendOtp = () => {
        setLoading(true);
        setErrorMessage('')
        dispatch(sendOtp({ phoneNumber, countryCode, portal: 'web_admin' })).then((action) => {
            // Dispatch the send OTP action
            setLoading(false);
            if (action.type === 'auth/sendotp/fulfilled') {
                setShowOtpModal(true);
                setErrorMessage('');
            } else if (action.type === 'auth/sendotp/rejected') {
                setErrorMessage(action.payload.error.message);
            }
        });
    };

    // Handle to verify otp
    const handleVerifyOtp = () => {
        const otpString = otp.join('');
        setLoading(true);
        setErrorMessage('');
        dispatch(verifyOtp({ countryCode, phoneNumber, otpId: authState.otpId, otp: otpString })) // Dispatch the verify OTP action
            .then((action) => {
                setLoading(false);
                if (action.type === 'auth/verifyOtp/fulfilled') {
                    const { data } = action.payload;
                    localStorage.setItem('access_token', data.access_token);
                    localStorage.setItem('refresh_token', data.refresh_token);
                    navigate('/advertisment');
                } else {
                    setErrorMessage(action.payload.error.message || 'Failed to verify OTP');
                }
            })
            .catch((err) => {
                setLoading(false);
                setErrorMessage('Error verifying OTP: ' + err.message);
            });
    };

    const handlePhoneChange = (event) => {
        if (event.target.value.length <= 10) {
            setPhoneNumber(event.target.value);
            setActiveButton(event.target.value.length === 10);
            setErrorMessage('')
        }
    };

    const handleOtpChange = (e, index) => {
        const newOtp = [...otp];
        newOtp[index] = e.target.value;
        setOtp(newOtp);
        setErrorMessage('')
    };

    return (
        <div className='login-form'>
            <div className="login-page">
                <div className="login-page-container">
                    <div className='login-page-cover-container'>
                        <div className='login-cover'>
                            <div className='login-logo'>
                                <div className='text-[24px] mt-[-45px] ml-[35px]'>
                                    <img src={image.IMG_LOGO} alt='' className='w-[180px] h-[78px] rounded-[24px]' /></div>
                            </div>
                            <img src={image.LogoHere} alt='' className='login-image' />
                        </div>
                        <div className="login-page-wrapper">
                            <div className="d-flex justify-content-center w-full">
                                <div className="visueats-logo-container">
                                    <div className="aside-logo">
                                        <div className='logo-text'>AdSimpl</div>
                                    </div>
                                    <div className="admin-panel">
                                        <span>Dashboard</span>
                                    </div>
                                </div>
                            </div>
                            <div className="login-container">
                                <div>{!showOtpModal ? 'Log In' : 'Verfication Code'}</div>
                                <p className="login-text">{!showOtpModal ? 'Sign in to continue to the Admin Panel.' : 'Please enter the OTP received via SMS on the mobile number you have provided'} </p>
                            </div>

                            {!loading ? !showOtpModal && <div className="email-container">
                                <div>
                                    <Input
                                        label="Phone Number"
                                        placeholder="Enter your phone number"
                                        type="tel"
                                        id="tel"
                                        name="phonenumber"
                                        value={phoneNumber}
                                        onChange={handlePhoneChange}
                                    />
                                    {errorMessage && <div style={{ color: 'red', fontSize: '12px', marginLeft: '15px', marginTop: '5px' }}>{errorMessage}</div>}
                                </div>
                                <button className={`flex items-center justify-center py-[8px] bg-[#10b981] text-white border-[#1d892b] text-sm w-full rounded-lg ${activeButton ? '' : 'opacity-50  cursor-not-allowed'}`}
                                    onClick={activeButton ? handleSendOtp : undefined}
                                    disabled={!activeButton}
                                >
                                    <div className="flex items-center">
                                        <div>Get OTP</div>
                                    </div>
                                </button>
                            </div> : <div className="flex items-center justify-center">
                                <Loader height='h-[190px]' paddingRight='pr-[3rem]' />
                            </div>}

                            {showOtpModal && <VerifyLoginOTP
                                showOtpModal={showOtpModal}
                                handleOtpChange={handleOtpChange}
                                otp={otp}
                                handleVerifyOtp={handleVerifyOtp}
                                setActiveButton={setActiveButton}
                                otpId={authState.otpId}
                                setOtp={setOtp}
                                errorMessage={errorMessage}
                                loading={loading}
                                setLoading={setLoading}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage

