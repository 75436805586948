import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler';
import { FiCamera } from 'react-icons/fi';
import PLUSICON from '../../../../assets/images/svg/PlusIcon.svg';
import AddDeleteAd from '../../../atoms/AddDeleteAd/AddDeleteAd'
import UploadImage from '../../../atoms/UploadImage/UploadImage'
import ErrorModal from '../../../atoms/error-modal/error-modal';
import Loader from '../../../atoms/Loader/Loader';
import './TemplateList.scss';

const TemplateList = (props) => {
    const { loading, addTemplate, templates, handleRemoveTemplate, handleAddTemplate, handleUploadImg, showEdit, setShowEdit, errorModal, setErrorModal, setShowEditDelete, showEditDelete } = props
    const handleEdit = (index) => {
        setShowEdit(showEdit === index ? null : index);
    }

    const handleEditDelete = (index) => {
        setShowEditDelete(showEditDelete === index ? null : index);
    }

    const handleClose = () => {
        setShowEdit(false)
        setShowEditDelete(false)
    }

    return (
        <div className='grid grid-cols-2 gap-6 py-8 max-h-[650px] overflow-auto'>
            <button
                className='upload-image-button' style={{ paddingTop: "2px", width: "300px", height: "220px" }} onClick={handleAddTemplate}>
                <img src={PLUSICON} alt='' />
            </button>

            {addTemplate?.map((template, index) => (
                <div key={index}>
                    <div className='relative flex justify-center'>
                        <div style={{ position: "relative" }}>
                            <div className="promo_profile-camera" onClick={() => handleEdit(index)}>
                                <FiCamera />
                            </div>
                        </div>
                        {template.url ? (
                            <button className='promotion-image-container'>
                                <img src={template.url} alt='Uploaded' className='promotion-image' />
                            </button>
                        ) : (
                            <UploadImage handleUploadImg={(imageList) => handleUploadImg(imageList, index)} />
                        )}
                    </div>
                    {showEdit === index && (
                        <OutsideClickHandler onOutsideClick={handleClose}>
                            <AddDeleteAd
                                uploadButton={!template.url}
                                handleRemoveTemplate={() => handleRemoveTemplate(index)}
                                handleUploadImg={(imageList) => handleUploadImg(imageList, index)}
                            />
                        </OutsideClickHandler>
                    )}
                </div>
            ))}

            {loading ? (
                <Loader />
            ) : (
                templates?.map((template, index) => (
                    <div key={template.id}>
                        <div className='relative flex justify-center'>
                            <div style={{ position: 'relative' }}>
                                <div className='promo_profile-camera' onClick={() => handleEditDelete(index)}>
                                    <FiCamera />
                                </div>
                            </div>
                            {template.template_url ? (
                                <button className='promotion-image-container'>
                                    <img src={template.template_url} alt='Uploaded' className='promotion-image' />
                                </button>
                            ) : (
                                <UploadImage handleUploadImg={(imageList) => handleUploadImg(imageList, index)} />
                            )}
                        </div>
                        {showEditDelete === index && (
                            <OutsideClickHandler onOutsideClick={handleClose}>
                                <AddDeleteAd
                                    handleRemoveTemplate={() => handleRemoveTemplate(index, template.id)}
                                    handleUploadImg={(imageList) => handleUploadImg(imageList, index)}
                                />
                            </OutsideClickHandler>
                        )}
                    </div>
                ))
            )}

            <ErrorModal errorModal={errorModal} setErrorModal={setErrorModal} line1="Sorry, we couldn't upload your file." line2='Please check the file type and size.' />
        </div>
    );
};

export default TemplateList