import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createOffer, getOffers, selectAllOffers, selectOfferStatus, toogleOfferStatus } from '../../../../redux/Slices/offerSlice'
import Loader from '../../../atoms/Loader/Loader'
import PageContainerHeader from '../../PageContainerHeader/PageContainerHeader'
import CouponDrawer from '../CouponDrawer/CouponDrawer'
import CouponContainer from '../CouponContainer/CouponContainer'

const CreateOffersContainer = () => {
    const [loading, setLoading] = useState(false);
    const [showCouponDrawer, setShowCouponDrawer] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [offerToEdit, setOfferToEdit] = useState(null);

    const dispatch = useDispatch()
    const offers = useSelector(selectAllOffers);
    const offerStatus = useSelector(selectOfferStatus)

    const onPressCancel = () => {
        setShowCouponDrawer(false)
        setErrorMessage('');
        setEditMode(false);
        setOfferToEdit(null);
    }

    // OfferStatus loading state
    useEffect(() => {
        if (offerStatus === 'loading') {
            setLoading(true);
        } else if (offerStatus === 'succeeded') {
            setLoading(false);
        }
    }, [offerStatus]);

    // Handle to Diable and Enabled offer status
    const onPressSwitchItem = async (offer) => {
        const { id, is_active } = offer;
        const newStatus = is_active ? 0 : 1;
        setLoading(true)
        try {
            const response = await dispatch(toogleOfferStatus({ id, is_active: newStatus })).unwrap()
            if (response.success) {
                dispatch(getOffers()); // Dispatch Get Offers action
            }
        } catch (error) {
            console.error('Error toggling offer status:', error);
        }
        setLoading(false)

    };

    // Handle to Edit the Coupon
    const onEditCoupon = (offer) => {
        setEditMode(true);
        setOfferToEdit(offer);
        setShowCouponDrawer(true);
    };

    useEffect(() => {
        dispatch(createOffer()) // Dispatch Create Offer action
        dispatch(getOffers()) // Dispatch Get Offers action
    }, [dispatch])

    return (
        <div>
            <PageContainerHeader title='Coupons' button buttonTitle='Add Coupon' onclickButton={() => { setShowCouponDrawer(true) }} />
            {!loading ? <>
                <CouponDrawer
                    showCouponDrawer={showCouponDrawer}
                    onPressCancel={onPressCancel}
                    loading={loading}
                    setLoading={setLoading}
                    setShowCouponDrawer={setShowCouponDrawer}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    editMode={editMode}
                    offerToEdit={offerToEdit}
                />

                <CouponContainer
                    offers={offers}
                    onPressSwitchItem={onPressSwitchItem}
                    setErrorMessage={setErrorMessage}
                    errorMessage={errorMessage}
                    onEditCoupon={onEditCoupon} /></>
                : <div className='h-[26rem] flex justify-center items-center'>
                    <Loader />
                </div>}
        </div>
    )
}

export default CreateOffersContainer
