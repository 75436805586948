import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BASE_URL } from "../../app/utils/apis/base";
import { ApiConstants } from "../../app/utils/apis";
import { apiClient } from "../../app/helper/axiosHelper";

// Async thunk for adding, uploading, fetching & delete templates 
export const addTemplates = createAsyncThunk('template/addTemplates', async ({ template_url, template_name }, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.ADDTEMPLATE}`, { template_url, template_name });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const uploadFile = createAsyncThunk('template/uploadFile', async (formData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.UPLOADFILE}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data.data?.imageUrl;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getTemplate = createAsyncThunk('template/getTemplate', async ({ page = 1, pageSize = 10 } = {}, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}${ApiConstants.GETTEMPLATE}`, {
            params: {
                page,
                pageSize
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
})

export const deleteTemplate = createAsyncThunk('template/deleteTemplate', async (template_id, { rejectWithValue }) => {
    try {
        const response = await apiClient.delete(`${BASE_URL}/api/admin/templates/${template_id}/delete`);
        return { id: template_id };
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
})

// Slices for template
const addTemplateSlice = createSlice({
    name: 'template',
    initialState: {
        templates: [],
        status: 'idle',
        error: null,
        pagination: {
            totalData: 0,
            currentPage: 1,
            pageSize: 10,
            totalPages: 1
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addTemplates.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(addTemplates.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.templates = action.payload.data.templates;
            })
            .addCase(addTemplates.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.error.message;
            })
            .addCase(getTemplate.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getTemplate.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.templates = action.payload.data.templates || [];
                state.pagination = action.payload.data.pagination;
            })
            .addCase(getTemplate.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.error?.message || action.payload : 'An unknown error occurred';
            })
            .addCase(deleteTemplate.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(deleteTemplate.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.templates = state.templates.filter(template => template.id !== action.payload.id);
            })
            .addCase(deleteTemplate.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.error.message || action.payload : 'An unknown error occurred';
            });
    },
});

// Selectors for accessing state
export const selectAllTemplates = (state) => state.template.templates
export const selectTemplatePagination = (state) => state.template.pagination;
export const selectTemplateStatus = (state) => state.template.status;

export default addTemplateSlice.reducer