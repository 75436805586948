import React, { useState } from 'react';
import { IoChevronBack } from "react-icons/io5";
import { image } from '../../../../app/utils/common/image';
import Loader from '../../../atoms/Loader/Loader';
import SaveModal from '../../../atoms/save-modal/save-modal';
import Button from '../../../atoms/Button/Button';
import Input from '../../../atoms/Input/Input';
import ErrorText from '../../../atoms/ErrorText/ErrorText';
import Pagination from '../../../atoms/Pagination/Pagination';
import OpsStoreContainer from '../opsStoreConatiner/opsStoreConatiner';

const OpsTeamContainer = (props) => {
    const { handlePageStoreChange, storePageNumbers, pagination, handlePageChange, pageNumbers, storePagination, opsUsers, handleRemoveUser, handleUserSave, handleUpdateUser, loading, handleInputChange, userError, handleEditClick, opsTeamStores, showTeam, setShowTeam , setLoading} = props
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const confirmDelete = (index, userId) => {
        setDeleteIndex({ index, userId });
        setDeleteModal(true);
    };

    const handleDelete = () => {
        if (deleteIndex !== null) {
            handleRemoveUser(deleteIndex.index, deleteIndex.userId);
            setDeleteModal(false);
            setDeleteIndex(null);
        }
    };
    return (
        <div className='card-border pb-[0px] overflow-auto'>
            <div className='flex justify-between py-[20px] pr-[30px] [border-bottom:_1px_solid_#e2e9e9] px-6'>
                <div className='flex items-center gap-2'>
                    {showTeam && <div onClick={() => { setShowTeam(false) }} className='cursor-pointer'><IoChevronBack size={20} /></div>}
                    {!showTeam && <Button buttonTitle='View Ops Shop' background={'bg-[#10b981]'} onClick={() => { setShowTeam(true) }} />}
                </div>
                {!showTeam && <Button buttonTitle='Save' background={'bg-[#10b981]'} onClick={() => { handleUpdateUser(); handleUserSave(); }} />}
            </div>
            {!showTeam && (
                <> {!loading ? <> <div className='overflow-auto max-h-[700px]'>
                    {opsUsers.map((user, index) => (
                        <div key={index}>
                            <div className='flex gap-8 items-center justify-between p-[2rem] [border-bottom:_1px_solid_#e2e9e9]'>
                                <div className='flex items-center'>
                                    <div className='text-2xl font-[600] text-[--themeColor]'>User {index + 1}</div>
                                    <div className='flex gap-10 ml-[5rem]'>
                                        <div>
                                            <Input
                                                label="User *"
                                                placeholder="Enter user name"
                                                type="text"
                                                id={`user-${index}`}
                                                name="category"
                                                value={user.name || ''}
                                                onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                                disabled={!user.isEditing}
                                            />
                                            {user.name === '' && <ErrorText message={'This is required field'} show={!user.name} />}
                                        </div>
                                        <div>
                                            <Input
                                                label="Phone Number *"
                                                placeholder="Enter user phone number"
                                                type="tel"
                                                id={`phone-${index}`}
                                                name="phonenumber"
                                                value={user.phone_no || ''}
                                                onChange={(e) => handleInputChange(index, 'phone_no', e.target.value)}
                                                disabled={!user.isEditing}
                                            />
                                            {user.isNew && userError && <ErrorText message={userError.error.message} show />}
                                            {!user.isNew && user.isEditing && userError && <ErrorText message={userError.error.message} show />}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex gap-8'>
                                    <img onClick={() => handleEditClick(index)} className='cursor-pointer w-[25px]' src={image.IMG_EDIT} />
                                    <img onClick={() => confirmDelete(index, user.id)} className='cursor-pointer w-[20px]' src={image.IMG_RED_DELETE} />
                                </div>

                            </div>
                            {deleteModal && (
                                <SaveModal
                                    text="Are you sure you want to delete this category?"
                                    deleteModal={deleteModal}
                                    setDeleteModal={setDeleteModal}
                                    buttons={['Yes', 'No']}
                                    onPressPositive={handleDelete}
                                    onPressNegative={() => { setDeleteModal(false); setDeleteIndex(null) }}
                                />
                            )}
                        </div>

                    ))}
                    <Pagination
                        pageNumbers={pageNumbers}
                        handlePageLink={handlePageChange}
                        totalProfile={pagination.totalData}
                        prevPage={() => handlePageChange(pagination.currentPage - 1)}
                        nextPage={() => handlePageChange(pagination.currentPage + 1)}
                        activePage={pagination.currentPage}
                    />
                </div>
                </> : (
                    <div className='max-h-[450px] flex items-center justify-center h-[450px]'>
                        <Loader />
                    </div>
                )}</>
            )}

            {showTeam && <><OpsStoreContainer
                loading={loading}
                opsTeamStores={opsTeamStores}
                handlePageStoreChange={handlePageStoreChange}
                storePageNumbers={storePageNumbers}
                storePagination={storePagination}
                setLoading={setLoading}
            />
            </>}

        </div>
    );
};

export default OpsTeamContainer;