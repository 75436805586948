import React from 'react'

const ErrorText = ({ message, show }) => {
    return (
        show ? <div style={{ color: 'red', fontSize: '12px' }}>
            {message}
        </div> : ""
    )
}

export default ErrorText
