import React from 'react';
import './EmptyTemplate.scss'

const EmptyTemplate = ({ line1, line2 }) => {
    return (
        <div className="no-result-found">
            <div className="no-result-found-text">
                <h5>{line1}</h5>
                <p>{line2}</p>
            </div>
            {/* <img src={image.IMG_EGG_SAD} alt="no-result-found" className="sad-egg-image" /> */}
        </div>
    )
}

export default EmptyTemplate
