import React from "react";
import { Modal } from "antd";
import { BiError } from 'react-icons/bi';

import './error-modal.scss';

const ErrorModal = props => {
    const { errorModal, setErrorModal, line1, line2 } = props

    return (
        <Modal
            className="ohno-modal"
            open={errorModal}
            onCancel={() => setErrorModal(false)}
            footer={null}
        >
            <div className="ohno-container">
                <div className="ohno-header">
                </div>
                <div className="ohno-main-container">
                    <div className="ohno-icon"><BiError /></div>
                    <div className="ohno-title">Oh no!</div>
                    <p className="ohno-text">
                        <center>
                            {line1}<br />{line2}
                        </center>
                    </p>
                    <button className="btns ohno-btn" onClick={() => setErrorModal(false)}>Try Again</button>
                </div>
            </div>
        </Modal>
    )
}

export default ErrorModal;