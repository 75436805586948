import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BASE_URL } from "../../app/utils/apis/base";
import { ApiConstants } from "../../app/utils/apis";
import { apiClient } from "../../app/helper/axiosHelper";

// Async thunk for fetching users
export const getUsers = createAsyncThunk('users/getUsers', async ({ search, page = 1, pageSize = 10 }, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}${ApiConstants.ALLUSERS}`, {
            params: { search, page, pageSize }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Slice for users
const usersSlice = createSlice({
    name: 'users',
    initialState: {
        searchResult: [],
        users: [],
        status: 'idle',
        error: null,
        pagination: {
            totalData: 0,
            currentPage: 1,
            pageSize: 10,
            totalPages: 1
        }
    },
    reducers: {
        setSearchResult(state, action) {
            state.searchResult = action.payload;
        },
        clearSearchResult(state) {
            state.searchResult = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload.data.users;
                state.pagination = action.payload.data.pagination;
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.error.message || action.payload : 'An unknown error occurred';
            });
    }
})

export const { setSearchResult, clearSearchResult } = usersSlice.actions;

// Selectors for accessing state
export const selectAllUsers = (state) => state.users.users;
export const selectUserPagination = (state) => state.users.pagination;
export const selectSearchResult = (state) => state.users.searchResult;

export default usersSlice.reducer