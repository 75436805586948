import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/utils/apis/base";
import { ApiConstants } from "../../app/utils/apis";
import { apiClient } from "../../app/helper/axiosHelper";

// Async thunk for fetching Agencies
export const getAgencyRequest = createAsyncThunk(
    'agency/getAgencyRequest',
    async ({ page = 1, pageSize = 10 }, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(`${BASE_URL}${ApiConstants.GETAGENCYREQUEST}`, {
                params: { page, pageSize }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateAgencyStatus = createAsyncThunk('agency/updateAgencyStatus', async ({ agencyId, status, reject_reason }, { dispatch, rejectWithValue }) => {
    try {
        const response = await apiClient.put(`${BASE_URL}${ApiConstants.UPDATEAGENCYSTATUS}`, { agency_id: agencyId, status, reject_reason });
        dispatch(getAgencyRequest({ page: 1, pageSize: 10 }));
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// Slice for Agencies
const allAgenciesSlice = createSlice({
    name: 'agency',
    initialState: {
        agencies: [],
        status: 'idle',
        error: null,
        pagination: {
            totalData: 0,
            currentPage: 1,
            pageSize: 10,
            totalPages: 1
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAgencyRequest.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getAgencyRequest.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.agencies = action.payload.data.agencies;
                state.pagination = action.payload.data.pagination;
            })
            .addCase(getAgencyRequest.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.error.message || action.payload : 'An unknown error occurred';
            })
            .addCase(updateAgencyStatus.fulfilled, (state, action) => {
                const updatedAgency = action.payload.data.stores;
                const updatedAgencyIndex = state.agencies.findIndex(agency => agency?.id === updatedAgency?.agencies?.id);
                if (updatedAgencyIndex !== -1) {
                    state.agencies[updatedAgencyIndex] = action.payload.data;
                }
            })
            .addCase(updateAgencyStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';
            })
    }
});

// Selectors for accessing state
export const selectAllAgencies = (state) => state.agency.agencies;
export const selectAgenciesPagination = (state) => state.agency.pagination;
export const selectAgenciesStatus = (state) => state.agency.status;

export default allAgenciesSlice.reducer;