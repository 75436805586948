import React, { FC } from "react";
import { Modal } from 'antd';
import { image } from "../../../app/utils/common/image";
import './save-modal.scss';

const SaveModal = props => {
    const { text, buttons, item, onPressNegative, onPressPositive, deleteModal, setDeleteModal, type } = props;

    return (
        <div >
            <Modal
                className="save-modal"
                open={deleteModal}
                onCancel={onPressNegative}
            >
                <div className="save-container">
                    <div className="save-header">
                        <button className="btn-modal close-button" aria-label="Close" onClick={() => setDeleteModal(false)}>
                            <img src={image.IMG_CLOSE} className="image_close" />
                        </button>
                    </div>
                    <div className="save-main-container">
                        <p>{text}</p>
                        <div className="save-continue-btn">
                            <button className="btns save-changes" onClick={() => {
                                if (type === 'delete-item') {
                                    onPressPositive(item)
                                } else {
                                    onPressPositive()
                                }
                            }}>{buttons[0]}</button>
                            <button className="continue-btn" onClick={() => onPressNegative()}>{buttons[1]}</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default SaveModal;