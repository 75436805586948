import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { getStoresAd, selectAllStore, selectStorePagination, selectStoreStatus } from '../../../redux/Slices/addStoreSlice';

import PageContainerHeader from '../PageContainerHeader/PageContainerHeader';
import ShopsContainer from './ShopContainer/ShopContainer';
import Pagination from '../../atoms/Pagination/Pagination';
import Loader from '../../atoms/Loader/Loader';

const ShopLists = () => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);

    const dispatch = useDispatch();
    const stores = useSelector(selectAllStore);
    const pagination = useSelector(selectStorePagination);
    const storeStatus = useSelector(selectStoreStatus)

    useEffect(() => {
        dispatch(getStoresAd({ page: currentPage, pageSize }));  // Dispatch the getStoresAd action
    }, [dispatch, currentPage, pageSize])

    // Storestatus loading state
    useEffect(() => {
        if (storeStatus === 'loading') {
            setLoading(true);
        } else if (storeStatus === 'succeeded') {
            setLoading(false);
        }
    }, [storeStatus]);

    const pageNumbers = [];
    for (let i = 1; i <= pagination?.totalPages; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    }
    return (
        <div>
            <PageContainerHeader title={'All Shops'} />
            {!loading ? <>
                <ShopsContainer stores={stores} />
                <Pagination
                    pageNumbers={pageNumbers}
                    handlePageLink={handlePageChange}
                    totalProfile={pagination?.totalData}
                    prevPage={() => handlePageChange(pagination?.currentPage - 1)}
                    nextPage={() => handlePageChange(pagination?.currentPage + 1)}
                    activePage={pagination?.currentPage}
                /></> :
                <div className='h-[26rem] flex justify-center items-center'> <Loader /></div>}
        </div>
    )
}

export default ShopLists
