import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BASE_URL } from "../../app/utils/apis/base";
import { ApiConstants } from "../../app/utils/apis";
import { apiClient } from "../../app/helper/axiosHelper";

// Async thunk for adding, fetching & deleting slots 
export const addSlots = createAsyncThunk('slots/addSlots', async (slotData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.ADDSLOTS}`, slotData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

export const getSlots = createAsyncThunk('slots/getSlots', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}${ApiConstants.GETSLOTS}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
})

export const deleteSlots = createAsyncThunk('slots/deleteSlots', async (slot_id, { rejectWithValue }) => {
    try {
        const response = await apiClient.delete(`${BASE_URL}/api/admin/slots/${slot_id}/delete`);
        return { id: slot_id };
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
})

// Slices for slots
const slotSlice = createSlice({
    name: 'slots',
    initialState: {
        slots: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addSlots.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(addSlots.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.slots = [...state.slots, action.payload.data.slots];
            })
            .addCase(addSlots.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';
            })
            .addCase(getSlots.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getSlots.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.slots = action.payload.data.slots;
            })
            .addCase(getSlots.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';
            })
            .addCase(deleteSlots.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(deleteSlots.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.slots = state.slots.filter(slot => slot.id !== action.payload.id);
            })
            .addCase(deleteSlots.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.error.message || action.payload : 'An unknown error occurred';
            });
    }
})

// Selectors for accessing state
export const selectAllSlots = (state) => state.slots?.slots;
export const selectSlotsStatus = (state) => state.slots?.status;
export const selectSlotsError = (state) => state.slots.error;

export default slotSlice.reducer