import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAllAds } from '../../../redux/Slices/adsRequestSlice';
import './SideNavBar.scss'

const SideNavbar = () => {
    const { pathname } = useLocation();
    const [adsNotify, setAdsNotify] = useState(0)

    const ads = useSelector(selectAllAds);

    useEffect(() => {
        if (ads && ads?.length) {
            const pendingAds = ads.filter((data) => data?.status === "PENDING")
            setAdsNotify(pendingAds.length)
        }
    }, [ads]);

    return (
        <div className='side-nav'>
            <div className='side-nav-list'>
                <a href='/advertisment' className={`mb-2 ${pathname === '/advertisment' ? "selectednav" : ""}`}  >
                    <span>Advertisment</span>
                    {adsNotify !== 0 && <span className='bg-[#10b981] text-white rounded-full p-[10px] w-[16px] h-[16px] flex items-center justify-center text-sm'>
                        {adsNotify}
                    </span>}
                </a>
                <a href='/users' className={`mb-2 ${pathname === '/users' ? "selectednav" : ""}`} >
                    <span>Users</span>
                </a>
                {/* <a href='/template' className={`mb-2 ${pathname === '/template' ? "selectednav" : ""}`}  >
                    <span>Create Template</span>
                </a> */}
                <a href='/transactions' className={`mb-2 ${pathname === '/transactions' ? "selectednav" : ""}`}  >
                    <span>All Transactions</span>
                </a>
                <a href='/offers' className={`mb-2 ${pathname === '/offers' ? "selectednav" : ""}`}  >
                    <span>Add Coupon</span>
                </a>
                <a href='/category' className={`mb-2 ${pathname === '/category' ? "selectednav" : ""}`}  >
                    <span>Add Category</span>
                </a>
                <a href='/slots' className={`mb-2 ${pathname === '/slots' ? "selectednav" : ""}`}  >
                    <span>Add Slots</span>
                </a>
                <a href='/ops-team' className={`mb-2 ${pathname === '/ops-team' ? "selectednav" : ""}`}  >
                    <span>Add Ops Team</span>
                </a>
                <a href='/shops' className={`mb-2 ${pathname === '/shops' ? "selectednav" : ""}`}  >
                    <span>Shop Lists</span>
                </a>
                <a href='/agency' className={`mb-2 ${pathname === '/agency' ? "selectednav" : ""}`}  >
                    <span>All Agencies</span>
                </a>
                {/* <a href='/reported-ads' className={`mb-2 ${pathname === '/reported-ads' ? "selectednav" : ""}`}  >
                    <span>Reported Ads</span>
                </a> */}
            </div>
        </div>
    );
}

export default SideNavbar
