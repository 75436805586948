import { Modal } from 'antd'
import React from 'react'
import { image } from '../../../../app/utils/common/image'

const AdViewModal = ({ openViewModal, setOpenViewModal, ad }) => {
    return (
        <div>
            <Modal open={openViewModal}
                footer={null}
                onCancel={() => setOpenViewModal(false)}>
                <div className='ad-container'>
                    <div className="ad-header">
                        <div className='text-[--themeColor] text-[18px] font-bold ml-[45%]'>Ad Details</div>
                        <button className="btn-modal close-button" aria-label="Close" onClick={() => setOpenViewModal(false)}>
                            <img src={image.IMG_CLOSE} className="image_close" />
                        </button>
                    </div>
                    <div className='ad-main-container text-[--themeColor]'>
                        <div className='flex gap-2'>
                            <div className='font-bold'>Name:</div>
                            <div>{ad?.user_name}</div>
                        </div>
                        <div className='flex gap-2'>
                            <div className='font-bold'>Phone Number:</div>
                            <div>{ad?.phone_no}</div>
                        </div>
                        <div className='flex gap-2'>
                            <div className='font-bold'>Order Number:</div>
                            <div> {ad?.order_id ? ad?.order_id : "Null"}</div>
                        </div>
                        <div className='flex gap-2'>
                            <div className='font-bold'>Paid Status:</div>
                            <div>{ad?.payment_status === 'SUCCESS' ? 'Paid' : 'Unpaid'}</div>
                        </div>

                        {ad?.additional_ads_data.map((adData, index) => (
                            <div key={index} className='flex flex-col gap-2'>
                                <div className='flex gap-2'>
                                    <div className='font-bold'>Display Quantity:</div>
                                    <div>{adData?.display_quantity}</div>
                                </div>
                                <div className='flex gap-2'>
                                    <div className='font-bold'>Frequency:</div>
                                    <div>{adData?.frequency}</div>
                                </div>
                                <div className='flex gap-2'>
                                    <div className='font-bold'>Date:</div>
                                    <div>{adData?.start_date} to {adData?.end_date}</div>
                                </div>

                            </div>
                        ))}
                        <div className='font-800 text-center flex items-center justify-center font-bold w-[100% ]'>Store Details</div>
                        {ad?.additional_ads_data?.map((adData, index) => (
                            <div key={index} className='flex flex-col gap-2 w-[100%]'>
                                {adData.store_displays?.map((store, storeIndex) => (
                                    <div key={storeIndex} className='flex flex-col gap-2 border-2 rounded-[12px] p-2 border-[--themeColor]' >
                                        <div className='flex gap-2'>
                                            <div className='font-bold'>Shop Name:</div>
                                            <div>{store?.shop_name}</div>
                                        </div>
                                        <div className='flex gap-2'>
                                            <div className='font-bold'>Store Address:</div>
                                            <div>{store?.address}, {store?.city}, {store?.state}</div>
                                        </div>
                                        <div className='flex gap-2'>
                                            <div className='font-bold'>TV Name:</div>
                                            <div>{store?.tv_name}</div>
                                        </div>
                                        <div className='flex gap-2'>
                                            <div className='font-bold'>Display Size:</div>
                                            <div>{store?.display_size}</div>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        ))}

                    </div>

                </div>
            </Modal>
        </div>
    )
}

export default AdViewModal