import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addOpsUsers, deleteOpsUsers, getOpsUsers, selectAllOpsUsers, selectOpsTeamPagination, selectOpsUsersError, selectOpsUsersStatus, updateOpsUsers } from '../../../redux/Slices/addOpsTeamSlice';
import { getStoresAd, selectAllStore, selectStorePagination, selectStoreStatus } from '../../../redux/Slices/addStoreSlice';

import PageContainerHeader from '../PageContainerHeader/PageContainerHeader';
import EmptyTemplate from '../../atoms/EmptyTemplate/EmptyTemplate';
import OpsTeamContainer from './OpsTeamContainer/OpsTeamContainer';
import AssignStoreDrawer from './AssignStoreDrawer/AssignStoreDrawer';

const OpsTeamScreen = () => {
    const [opsUsers, setOpsUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentStorePage, setCurrentStorePage] = useState(1);
    const [pageSize] = useState(10);
    const [showAssignDrawer, setShowAssignDrawer] = useState(false)
    const [showTeam, setShowTeam] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');

    const dispatch = useDispatch();
    const allUsers = useSelector(selectAllOpsUsers);
    const userStatus = useSelector(selectOpsUsersStatus);
    const userError = useSelector(selectOpsUsersError);
    const pagination = useSelector(selectOpsTeamPagination);

    //Selectors for Ops Team User state 
    const opsTeamStores = useSelector(selectAllStore);
    const storePagination = useSelector(selectStorePagination);
    const storeStatus = useSelector(selectStoreStatus)

    // User status loading state
    useEffect(() => {
        if (userStatus === 'loading') {
            setLoading(true);
        } else if (userStatus === 'succeeded') {
            setLoading(false);
        }
    }, [userStatus]);

    // store Status loading state
    useEffect(() => {
        if (storeStatus === 'loading') {
            setLoading(true);
        } else if (storeStatus === 'succeeded') {
            setLoading(false);
        }
    }, [storeStatus]);

    useEffect(() => {
        dispatch(getOpsUsers({ page: currentPage, pageSize })); // Dispatch the get OPS Users action
    }, [dispatch, currentPage, pageSize]);


    useEffect(() => {
        dispatch(getStoresAd({ page: currentStorePage, pageSize })); // Dispatch the get Stores Ad action
    }, [dispatch, currentStorePage, pageSize])

    // Fetch all ops users
    useEffect(() => {
        if (allUsers && allUsers.length > 0) {
            setOpsUsers(allUsers.map(user => ({ ...user, isNew: false, isEditing: false })));
        }
    }, [allUsers]);

    const handleAddOpsUser = () => {
        setOpsUsers([{ name: '', phone_no: '', country_code: "+91", isNew: true, isEditing: true }, ...opsUsers]);
    };

    // Handle to Remove Ops User
    const handleRemoveUser = (index, userId) => {
        setLoading(true);
        if (userId) {
            dispatch(deleteOpsUsers(userId))
                .unwrap()
                .then(() => {
                    dispatch(getOpsUsers());
                });
        } else if (!userId) {
            const temp = [...opsUsers];
            temp.splice(index, 1);
            setOpsUsers(temp);
        }
        setLoading(false);
    };

    const handleInputChange = (index, field, value) => {
        const temp = [...opsUsers];
        temp[index][field] = value;
        setOpsUsers(temp);
    };

    const handleEditClick = (index) => {
        const temp = [...opsUsers];
        temp[index].isEditing = true;
        setOpsUsers(temp);
    };

    // Handle to Update Ops User
    const handleUpdateUser = async () => {
        setLoading(true);
        const updatedUsers = opsUsers.filter(user => user.isEditing && !user.isNew);
        try {
            for (const user of updatedUsers) {
                await dispatch(updateOpsUsers({ userID: user.id, name: user.name, phoneNumber: user.phone_no })).unwrap();
                await dispatch(getOpsUsers());
            }
        } catch (error) {
            console.error('Error updating users:', error);
        }
        setLoading(false);
    };

    // Handle to Save Ops User
    const handleUserSave = async () => {
        setLoading(true);
        const newUsers = opsUsers.filter(usr => usr.isNew && usr.name && usr.phone_no && usr.country_code);
        try {
            for (const users of newUsers) {
                await dispatch(addOpsUsers({ name: users.name, phone_no: users.phone_no, country_code: users.country_code })).unwrap();
                await dispatch(getOpsUsers());
            }
        } catch (error) {
            console.error('Error saving users:', error);
        }
        setLoading(false);
    };

    // Pagination for ops team
    const pageNumbers = [];
    for (let i = 1; i <= (pagination?.totalPages || 0); i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Pagination for opsteam store
    const storePageNumbers = [];
    for (let i = 1; i <= (storePagination?.totalPages || 0); i++) {
        storePageNumbers.push(i);
    }

    const handlePageStoreChange = (storePageNumbers) => {
        setCurrentStorePage(storePageNumbers);
    };

    return (
        <div>
            <PageContainerHeader title='Ops Team' button buttonTitle='Add OpsTeam' onclickButton={handleAddOpsUser} button1 buttonTitle1='Assign OpsTeam' onclickButton2={() => { setShowAssignDrawer(true) }} />
            {opsUsers.length === 0 ? (
                <EmptyTemplate line1='Oops! Something got scrambled.' line2='Add an Ops Team Member' />
            ) : (
                <><OpsTeamContainer
                    opsUsers={opsUsers}
                    handleRemoveUser={handleRemoveUser}
                    handleUserSave={handleUserSave}
                    loading={loading}
                    setLoading={setLoading}
                    handleInputChange={handleInputChange}
                    userError={userError}
                    handleEditClick={handleEditClick}
                    handleUpdateUser={handleUpdateUser}
                    opsTeamStores={opsTeamStores}
                    showTeam={showTeam}
                    setShowTeam={setShowTeam}
                    storePagination={storePagination}
                    pageNumbers={pageNumbers}
                    handlePageChange={handlePageChange}
                    pagination={pagination}
                    storePageNumbers={storePageNumbers}
                    handlePageStoreChange={handlePageStoreChange}
                />
                </>
            )}
            <AssignStoreDrawer
                showAssignDrawer={showAssignDrawer}
                opsUsers={opsUsers}
                setLoading={setLoading}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                setShowAssignDrawer={setShowAssignDrawer}
            />
        </div>
    );
};

export default OpsTeamScreen;