import React from 'react';
import Loader from '../../../atoms/Loader/Loader';

const AllTransactionsContainer = ({ transactions, loading }) => {
    return (
        <>
            <div className='h-[740px] overflow-auto'>
                <table className='table-default w-full'>
                    <thead>
                        <tr>
                            <th className='text-start'>Date</th>
                            <th>Receipt</th>
                            <th>Order Number</th>
                            <th>Amount</th>
                            <th>Currency</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ?
                            <tr>
                                <td colSpan="3"> <Loader height='h-[20rem]' />
                                </td>
                            </tr>
                            : <div className='p-[12px] contents '>
                                {transactions.map((transc, index) => {
                                    const createdAt = new Date(transc.createdAt).toISOString().split('T')[0];
                                    return (
                                        <tr className='m-[12px] [border-bottom:_.8px_solid_#e2e9e9]'>
                                            <td className='text-start capitalize'>{createdAt}</td>
                                            <td>{transc.payment_id}</td>
                                            <td>{transc.order_id}</td>
                                            <td>{transc.cost}</td>
                                            <td>{transc.currency}</td>
                                            <td>
                                                <div className='flex items-center justify-center'>
                                                    <div className={`Success-bg ${transc.status === 'PENDING' ? 'bg-[#f59e0b]' : transc.status === 'SUCCESS' ? 'bg-[#10b981]' : 'bg-[#8b0000]'} `}>{transc.status}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </div>
                        } </tbody></table>
            </div>
        </>
    )
}

export default AllTransactionsContainer
