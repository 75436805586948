import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import usersSlice from "./Slices/usersSlice";
import authSlice from "./Slices/authSlice";
import adsRequestSlice from "./Slices/adsRequestSlice";
import addTemplateSlice from "./Slices/addTemplateSlice";
import transactions from "./Slices/transactions";
import offerSlice from "./Slices/offerSlice";
import addCategorySlice from "./Slices/addCategorySlice";
import addSlotSlice from "./Slices/addSlotSlice";
import addOpsTeamSlice from "./Slices/addOpsTeamSlice";
import addStoreSlice from "./Slices/addStoreSlice";
import agencySlice from "./Slices/agencySlice";

const persistConfig = {
    key: 'root',
    storage,
}

const combineReducer = combineReducers({
    users: usersSlice,
    auth: authSlice,
    ads: adsRequestSlice,
    template: addTemplateSlice,
    transc: transactions,
    offers: offerSlice,
    categories: addCategorySlice,
    slots: addSlotSlice,
    opsTeam: addOpsTeamSlice,
    stores: addStoreSlice,
    agency: agencySlice
})
const persistedReducer = persistReducer(persistConfig, combineReducer)


export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export const persistor = persistStore(store)