import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAdSearchResult, getAdRequests, selectAdPagination, selectAdStatus, selectAllAds } from '../../../../redux/Slices/adsRequestSlice';
import Pagination from '../../../atoms/Pagination/Pagination';
import FilterAd from '../../../atoms/FilterAd/FilterAd';
import PageContainerHeader from '../../PageContainerHeader/PageContainerHeader';
import AdvertismentContainer from '../AdvertismentContainer/AdvertismentContainer';
import AdvertismentSearch from '../AdvertismentSearch/AdvertismentSearch';

const AdvertismentList = () => {
    const [searchString, setSearchString] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState('All')
    const [pageSize] = useState(10);

    const dispatch = useDispatch();
    const ads = useSelector(selectAllAds);
    const adStatus = useSelector(selectAdStatus);
    const pagination = useSelector(selectAdPagination);
    const pageNumbers = [];

    // Dispatch Get Ads Request action
    useEffect(() => {
        if (searchString) {
            dispatch(getAdRequests({ search: searchString, page: currentPage, pageSize, adStatus: sortBy }));
        } else {
            dispatch(getAdRequests({ page: currentPage, pageSize, status: sortBy }));
        }
    }, [dispatch, searchString, currentPage, pageSize, sortBy]);

    // AdStatus Loading state
    useEffect(() => {
        if (adStatus === 'loading') {
            setLoading(true);
        } else if (adStatus === 'succeeded') {
            setLoading(false);
        }
    }, [adStatus]);

    // Search By Name 
    const onChangeSearch = (search = '') => {
        setSearchString(search);
        setCurrentPage(1);
    }

    const onPressCancelSearch = () => {
        setSearchString('');
        setCurrentPage(1);
        dispatch(clearAdSearchResult()); // Clear search results in Redux state
    }

    // handle Pagination
    for (let i = 1; i <= pagination.totalPages; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        dispatch(getAdRequests({ search: searchString, page: pageNumber, pageSize, status: sortBy }));
    }

    return (
        <div>
            <PageContainerHeader title={'All Advertisment'} />
            <AdvertismentSearch
                onChangeSearch={onChangeSearch}
                onPressCancelSearch={onPressCancelSearch}
                searchString={searchString}
                placeholder='Search by Name' />
            {/* <div className='grid mb-4'>
                <FilterAd sortBy={sortBy} setSortBy={setSortBy} />
            </div> */}
            <AdvertismentContainer
                ads={ads}
                setLoading={setLoading}
                loading={loading}
                pagination={pagination}
                handlePageChange={handlePageChange}
                searchString={searchString}
                sortBy={sortBy}
                setSortBy={setSortBy}
            />
            <Pagination
                pageNumbers={pageNumbers}
                handlePageLink={handlePageChange}
                prevPage={() => handlePageChange(pagination.currentPage - 1)}
                nextPage={() => handlePageChange(pagination.currentPage + 1)}
                totalProfile={pagination.totalData}
                activePage={pagination.currentPage}
            />
        </div>
    )
}

export default AdvertismentList;