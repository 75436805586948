import React from 'react'
import ReactImageUploading from "react-images-uploading";
import UPLOADICON from '../../../assets/images/upload-image-icon.png'
import './UploadImage.scss'

const UploadImage = ({ handleUploadImg, }) => {
    return (
        <div>
            <ReactImageUploading
                multiple={false}
                value={[]}
                onChange={handleUploadImg}
                allowNonImageType={true}
                maxFileSize={3000000}
                // onError={(e) => { setErrorModal(true) }}
                acceptType={['jpeg', 'jpg', 'png', 'svg']}
            >
                {({ onImageUpload }) => (
                    <div onClick={onImageUpload}>
                        <button
                            className='upload-image-button' style={{ paddingTop: "2px", width: "300px", height: "220px" }}>
                            <img src={UPLOADICON} className="image_gallery_icon" alt='' />
                            <span className="upload-image-text">Upload File</span>
                            <span className="upload-image_subtext" >Type: JPEG, JPG, PNG<br /> Size: Images Max 3MB <br />
                            </span>
                            <span className="upload-image_subtext" >Dimensions: Width: 200 pixels <br /> Height: 125 pixels </span>
                        </button>
                    </div>
                )}
            </ReactImageUploading>
        </div>

    )
}

export default UploadImage