import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearToken, logout } from '../../../redux/Slices/authSlice';

const LogOutButton = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const onPressLogOut = async () => {
        localStorage.clear();
        dispatch(clearToken());

        try {
            await dispatch(logout()).unwrap();
        } catch (error) {
            console.error('Logout failed:', error);
        }

        // Navigate to login page
        navigate("/login");
    }
    return (
        <div>
            <div className='btns' >
                <button className='btn btn-logout' onClick={onPressLogOut}>Log Out</button>
            </div>
        </div>
    )
}

export default LogOutButton
