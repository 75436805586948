import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BASE_URL } from "../../app/utils/apis/base";
import { ApiConstants } from "../../app/utils/apis";
import { apiClient } from "../../app/helper/axiosHelper";

// Async thunk for adding, fetching & deleting OpsUser 
export const addOpsUsers = createAsyncThunk('opsTeam/addOpsUsers', async (userData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.ADDOPSTEAM}`, userData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

export const getOpsUsers = createAsyncThunk('opsTeam/getOpsUsers', async ({ page = 1, pageSize = 10 } = {}, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}${ApiConstants.GETOPSTEAM}`, {
            params: {
                page,
                pageSize
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
})

export const deleteOpsUsers = createAsyncThunk('opsTeam/deleteOpsUsers', async (user_id, { rejectWithValue }) => {
    try {
        const response = await apiClient.delete(`${BASE_URL}/api/admin/ops/members/${user_id}/delete`);
        return { id: user_id };
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
})

export const updateOpsUsers = createAsyncThunk('opsTeam/updateOpsUsers', async ({ userID, name, phoneNumber }, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.UPDATEOPSTEAM}`, { user_id: userID, name, phone_no: phoneNumber });
        return response.data
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
})

const opsTeamSlice = createSlice({
    name: 'opsTeam',
    initialState: {
        users: [],
        status: 'idle',
        error: null,
        pagination: {
            totalData: 0,
            currentPage: 1,
            pageSize: 10,
            totalPages: 1
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addOpsUsers.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(addOpsUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = [...state.users, action.payload.data.users];
            })
            .addCase(addOpsUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';
            })
            .addCase(getOpsUsers.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getOpsUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload.data.users;
                state.pagination = action.payload.data.pagination;
            })
            .addCase(getOpsUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';
            })
            .addCase(deleteOpsUsers.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(deleteOpsUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = state.users.filter(user => user.id !== action.payload.id);
            })
            .addCase(deleteOpsUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.error.message || action.payload : 'An unknown error occurred';
            })
            .addCase(updateOpsUsers.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(updateOpsUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.users.findIndex(user => user.id === action.payload.id);
                if (index !== -1) {
                    state.users[index] = action.payload;
                }
            })
            .addCase(updateOpsUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';

            });
    }
})

export const selectAllOpsUsers = (state) => state.opsTeam?.users;
export const selectOpsUsersStatus = (state) => state.opsTeam?.status;
export const selectOpsUsersError = (state) => state.opsTeam.error;
export const selectOpsTeamPagination = (state) => state.opsTeam.pagination;


export default opsTeamSlice.reducer