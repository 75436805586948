import React from 'react';
import Pagination from '../../../atoms/Pagination/Pagination';
import UsersContainer from '../UsersContainer/UsersContainer';
import AdvertismentSearch from '../../AdvertismentScreen/AdvertismentSearch/AdvertismentSearch';

const UsersListContainer = (props) => {
    const { usersData, handlePageChange, pageNumbers, loading, prevPage, nextPage, totalProfile, activePage, onChangeSearch, onPressCancelSearch, searchString } = props
    return (
        <div className='card-border card-body min-h-[250px]'>
            <div className='grid gap-4 items-center '>
                {/* <div className='col-start-1 col-end-3 font-bold text-base text-sky-900 py-2 px-4 bg-sky-50 border-sky-900 border rounded-lg w-auto'>
                    Result {pageNumbers} of {totalProfile}
                </div> */}
                <AdvertismentSearch
                    onChangeSearch={onChangeSearch}
                    onPressCancelSearch={onPressCancelSearch}
                    searchString={searchString}
                    placeholder='Search by Name' />
            </div>
            <>
                <UsersContainer usersData={usersData} searchString={searchString} loading={loading} />
                <Pagination
                    pageNumbers={pageNumbers}
                    handlePageLink={handlePageChange}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    totalProfile={totalProfile}
                    activePage={activePage}
                />
            </>
        </div>
    );
};

export default UsersListContainer;