import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addSlots, deleteSlots, getSlots, selectAllSlots, selectSlotsStatus } from '../../../redux/Slices/addSlotSlice'

import PageContainerHeader from '../PageContainerHeader/PageContainerHeader'
import SlotContainer from './SlotContainer/SlotContainer'
import EmptyTemplate from '../../atoms/EmptyTemplate/EmptyTemplate'

const SlotsScreen = () => {
    const [slots, setSlots] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const dispatch = useDispatch();
    const allSlots = useSelector(selectAllSlots);
    const slotStatus = useSelector(selectSlotsStatus);

    // slot status loading state
    useEffect(() => {
        if (slotStatus === 'loading') {
            setLoading(true);
        } else if (slotStatus === 'succeeded') {
            setLoading(false);
        }
    }, [slotStatus]);

    useEffect(() => {
        dispatch(getSlots());  // Dispatch the getSLots action
    }, [dispatch]);

    useEffect(() => {
        setLoading(true);
        if (allSlots && allSlots.length > 0) {
            setSlots(allSlots.map(slot => ({ ...slot, isNew: false })));
        } else {
            setSlots([]);
        }
        setLoading(false);
    }, [allSlots]);

    const handleAddSlot = () => {
        setSlots([{ start_time: '', end_time: '', price: '', isNew: true }, ...slots]);
    };

    const handleRemoveSlots = (index, slotId) => {
        setLoading(true);
        if (!slotId) {
            const temp = [...slots];
            temp.splice(index, 1);
            setSlots(temp);
            setLoading(false);
        } else if (slotId) {
            dispatch(deleteSlots(slotId)).unwrap() // Dispatch the deleteSLot action
                .then(() => {
                    dispatch(getSlots()).unwrap(); // Dispatch the getSLots action
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const validateSlot = (slot) => {
        const errors = {};
        if (!slot.start_time) errors.start_time = 'Start time is required';
        if (!slot.end_time) errors.end_time = 'End time is required';
        if (!slot.price) errors.price = 'Price is required';

        return errors;
    };

    // Validate all slots and update the errors state
    const validateAllSlots = () => {
        const newErrors = slots.map(slot => validateSlot(slot));
        setErrors(newErrors);
        return newErrors.every(err => Object.keys(err).length === 0);
    };

    const handleInputChange = (index, field, value) => {
        const temp = [...slots];
        if (field === 'start_time' || field === 'end_time') {
            temp[index][field] = value ? value.format('HH:mm:ss') : '';
        } else {
            temp[index][field] = value;
        }
        setSlots(temp);

        const newErrors = [...errors];
        newErrors[index] = validateSlot(temp[index]);
        setErrors(newErrors);
    };

    // Handle to save slot
    const handleSlotsSave = async () => {
        setLoading(true);
        const newSlots = slots.filter(slot => slot.isNew && slot.start_time && slot.end_time && slot.price);

        try {
            if (validateAllSlots()) {
                for (const slot of newSlots) {
                    await dispatch(addSlots({
                        start_time: slot.start_time,
                        end_time: slot.end_time,
                        price: slot.price
                    })).unwrap();
                }
                await dispatch(getSlots()).unwrap();
            }
        } catch (error) {
            console.error('Error saving slots:', error);
        }
        setLoading(false);
    };

    return (
        <div>
            <PageContainerHeader title='Slots' button buttonTitle='Add Slots' onclickButton={handleAddSlot} />
            {slots.length === 0 ?
                <EmptyTemplate line1='Oops! Something got scrambled.' line2='Add a Slot' /> :
                <SlotContainer
                    handleRemoveSlots={handleRemoveSlots}
                    handleSlotsSave={handleSlotsSave}
                    slots={slots}
                    setSlots={setSlots}
                    loading={loading}
                    errors={errors}
                    handleInputChange={handleInputChange}
                />}
        </div>
    )
}

export default SlotsScreen
