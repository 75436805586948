import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BASE_URL } from "../../app/utils/apis/base";
import { ApiConstants } from "../../app/utils/apis";
import { apiClient } from "../../app/helper/axiosHelper";

// Async thunk for adding, fetching & deleting Category 
export const addCategory = createAsyncThunk('categories/addCategory', async (categoryData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.ADDCATEGORY}`, categoryData,);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

export const getCategory = createAsyncThunk('categories/getCategory', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}${ApiConstants.GETCATEGORIES}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
})

export const deleteCategory = createAsyncThunk('categories/deleteCategory', async (category_id, { rejectWithValue }) => {
    try {
        const response = await apiClient.delete(`${BASE_URL}/api/admin/categories/${category_id}/delete`);
        return { id: category_id };
    } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
})

// Slice for Category
const categorySlice = createSlice({
    name: 'categories',
    initialState: {
        categories: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addCategory.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(addCategory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categories = [...state.categories, action.payload.data.category];
            })
            .addCase(addCategory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';
            })
            .addCase(getCategory.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getCategory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categories = action.payload.data.categories;
            })
            .addCase(getCategory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.message || action.payload : 'An unknown error occurred';
            })
            .addCase(deleteCategory.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categories = state.categories.filter(category => category.id !== action.payload.id);
            })
            .addCase(deleteCategory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.error.message || action.payload : 'An unknown error occurred';
            });
    }
})

// Selectors for accessing state
export const selectAllCategories = (state) => state.categories?.categories;
export const selectCategoryStatus = (state) => state.categories?.status;
export const selectCategoryError = (state) => state.categories.error;

export default categorySlice.reducer