import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import LoginPage from '../../components/organisms/LoginPage/LoginPage'
import AdvertismentPage from '../../components/organisms/AdvertismentPage/AdvertismentPage'
import UsersPage from '../../components/organisms/UsersPage/UsersPage'
import CreateTemplatePage from '../../components/organisms/CreateTemplatePage/CreateTemplatePage'
import Transactions from '../../components/organisms/Transactions/Transactions'
import CreateOffer from '../../components/organisms/CreateOffer/CreateOffer'
import AddCategory from '../../components/organisms/AddCategory/AddCategory'
import AddSlot from '../../components/organisms/AddSlot/AddSlot'
import AddOpsTeam from '../../components/organisms/AddOpsTeam/AddOpsTeam'
import ShopList from '../../components/organisms/ShopList/ShopList'
import ReportedAds from '../../components/organisms/ReportedAds/ReportedAds'
import AllAgency from '../../components/organisms/AllAgency/AllAgency'

const AppRoutes = () => {
    return (
        <>
            <Routes>
                <Route path='/login' element={<LoginPage />} />
                <Route path='/advertisment' element={<AdvertismentPage />} />
                <Route path='/users' element={<UsersPage />} />
                <Route path='/template' element={<CreateTemplatePage />} />
                <Route path='/transactions' element={<Transactions />} />
                <Route path='/offers' element={<CreateOffer />} />
                <Route path='/category' element={<AddCategory />} />
                <Route path='/slots' element={<AddSlot />} />
                <Route path='/ops-team' element={<AddOpsTeam />} />
                <Route path='/shops' element={<ShopList />} />
                <Route path='/agency' element={<AllAgency />} />
                <Route path='/reported-ads' element={<ReportedAds />} />
                <Route path='/' element={<Navigate to="/login" />} />
            </Routes>
        </>
    )
}

export default AppRoutes
