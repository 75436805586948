import React from 'react'
import Header from '../Header/Header'
import SideNavbar from '../SideNavBar/SideNavBar'
import CategoryScreen from '../../molecules/CategoryScreen/CategoryScreen'

const AddCategory = () => {
    return (
         <div>
            <Header />
            <div className='pt-[58px] flex'>
                <SideNavbar />
                <div className='w-[100%] p-6'>
                    <CategoryScreen />
                </div>
            </div>
        </div>
    )
}

export default AddCategory
