import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import { updateStoreStatus } from '../../../../redux/Slices/addStoreSlice';
import { IoEyeOutline } from 'react-icons/io5';
import Loader from '../../../atoms/Loader/Loader';
import Pagination from '../../../atoms/Pagination/Pagination';
import Button from '../../../atoms/Button/Button';
import AdStatus from '../../../atoms/reserv-status/ad-status';
import RejectModal from '../../../atoms/reject-modal/reject-modal';
import OpsDisplayModal from './OpsDisplayModal';

const OpsStoreContainer = ({ opsTeamStores, loading, storePageNumbers, storePagination, handlePageStoreChange, setLoading }) => {
    const [openDisplayModal, setOpenDisplayModal] = useState(false);
    const [selectedAdData, setSelectedAdData] = useState(null);
    const [showStatus, setShowStatus] = useState(false)
    const [selectedStoreId, setSelectedStoreId] = useState(null);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [rejectReason, setRejectReason] = useState('');

    const dispatch = useDispatch();

    const handleViewStore = (ad) => {
        setSelectedAdData(ad);
        setOpenDisplayModal(true);
    }

    const handleShowStatus = (index) => {
        setShowStatus(showStatus === index ? null : index);
    }

    const handleClose = () => {
        setShowStatus(false)
    }

    const handleAcceptStore = (storeId) => {
        dispatch(updateStoreStatus({ storeId, status: 'APPROVE' }));
        setShowStatus(false)
    }

    const handleRejectStore = (storeId) => {
        setSelectedStoreId(storeId);
        setShowRejectModal(true);
        setShowStatus(false)
    }

    // Handle to Reject the Store on Click of Save button
    const handleRejectedSaveBtn = () => {
        setLoading(true)
        if (selectedStoreId && rejectReason) {
            dispatch(updateStoreStatus({ storeId: selectedStoreId, status: 'REJECT', reject_reason: rejectReason }));
            setShowRejectModal(false);
            setRejectReason('');
            setSelectedStoreId(null);
            setShowStatus(false)
        }
        setLoading(false)
    }

    return (
        <> <>
            <div className='h-[740px] overflow-auto'>
                <table className='table-default w-full'>
                    <thead>
                        <tr>
                            <th className='text-start'>Date</th>
                            <th>Member Name</th>
                            <th>Shop Name</th>
                            <th>Shop Owner's Name</th>
                            <th>Shop's address</th>
                            <th>Phone Number</th>
                            <th>Display Status</th>
                            <th>Store Status</th>
                            <th>Ops Request</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ?
                            <tr>
                                <td colSpan="7"> <Loader height='h-[550px]' /></td>
                            </tr>
                            :
                            <div className='p-[12px] contents '>
                                {opsTeamStores.map((store, index) => {
                                    const createdAt = new Date(store.updatedAt).toISOString().split('T')[0];
                                    return (
                                        <tr className='m-[12px] [border-bottom:_.8px_solid_#e2e9e9]'>
                                            <td className='text-start capitalize w-[140px]'>{createdAt}</td>
                                            <td>{store.assignee_name}</td>
                                            <td>{store.shop_name}</td>
                                            <td>{store.owner_name}</td>
                                            <td>{store.address}, {store.city}, {store.state}</td>
                                            <td>{store.owner_phone_no}</td>
                                            <td className='flex justify-center items-center' >
                                                <div className={`Success-bg ${store.display_status === 'PENDING' ? 'bg-[#f59e0b]' : store.display_status === 'COMPLETED' ? 'bg-[#10b981]' : 'bg-[#8b0000]'}`}> {store.display_status}</div>
                                            </td>
                                            <td className='table-cell justify-center items-center' >
                                                <div className={`Success-bg ${store.store_status === 'PENDING' ? 'bg-[#f59e0b]' : store.store_status === 'COMPLETED' ? 'bg-[#10b981]' : 'bg-[#8b0000]'}`}> {store.store_status}</div>
                                            </td>
                                            <td><div className='flex items-center justify-center'>{store.admin_approval_status === 'APPROVE' ? (
                                                <Button buttonTitle={'Accepted'} background='bg-[#228B22]' className='cursor-not-allowed' disabled onClick={() => { }} />
                                            ) : store.admin_approval_status === 'REJECT' ? (
                                                <Button buttonTitle={'Rejected'} background='bg-[#8b0000]' disabled className='cursor-not-allowed' onClick={() => { }} />
                                            ) : (
                                                <>
                                                    <Button buttonTitle={'Pending'} background='bg-[#f59e0b]' onClick={() => { handleShowStatus(index) }} />
                                                    {showStatus === index && (
                                                        <OutsideClickHandler onOutsideClick={handleClose}>
                                                            <AdStatus handleRejectAds={() => handleRejectStore(store.id)} handleAcceptAds={() => handleAcceptStore(store.id)} store={store} />
                                                        </OutsideClickHandler>
                                                    )}
                                                </>
                                            )} </div></td>
                                            <td>
                                                <div className='flex justify-center text-[1.2rem] cursor-pointer' onClick={() => handleViewStore(store)}>
                                                    <IoEyeOutline />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </div>
                        }
                    </tbody>
                </table>
                <Pagination
                    pageNumbers={storePageNumbers}
                    handlePageLink={handlePageStoreChange}
                    totalProfile={storePagination.totalData}
                    prevPage={() => handlePageStoreChange(storePagination.currentPage - 1)}
                    nextPage={() => handlePageStoreChange(storePagination.currentPage + 1)}
                    activePage={storePagination.currentPage}
                />
                {openDisplayModal && <OpsDisplayModal
                    openDisplayModal={openDisplayModal}
                    setOpenDisplayModal={setOpenDisplayModal}
                    opsTeamStores={opsTeamStores} store={selectedAdData} />}

                <RejectModal showRejectModal={showRejectModal} setShowRejectModal={setShowRejectModal} handleRejectedSaveBtn={handleRejectedSaveBtn} rejectReason={rejectReason} setRejectReason={setRejectReason} />

            </div>

        </></>
    )
}

export default OpsStoreContainer
