import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';

import { resendOtp } from '../../../redux/Slices/authSlice';
import Button from '../../atoms/Button/Button'

const VerifyLoginOtp = (props) => {
  const { handleOtpChange, otp, handleVerifyOtp, setActiveButton, otpId, setOtp, errorMessage, loading, setLoading } = props
  
  const dispatch = useDispatch();
  const inputsRef = useRef([]);
  const [countdown, setCountdown] = useState(40);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index] === "" && index > 0) {
        let finalIdex = index - 1;
        inputsRef.current[finalIdex].focus();
      }
      setActiveButton(false);
    }
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === '') {
      handleOtpChange(e, index);
      if (value !== '' && index < 3) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  // Handle to Resend otp
  const handleResendOtp = async () => {
    setLoading(true);
    if (countdown === 0) {
      setLoading(false);
      await dispatch(resendOtp({ otpId })); // Dispatch the resend OTP action
      setCountdown(40); // Reset the countdown after resend
      setOtp(["", "", "", ""]);
    }
  };

  return (
    <> {!loading &&
      <div className='flex flex-col gap-4'>
        <div className='flex mt-[2rem] gap-2 '>
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(el) => (inputsRef.current[index] = el)}
              className='otp-input'
            />))}
        </div>
        <div>{errorMessage && <div style={{ color: 'red', fontSize: '12px', marginLeft: '15px' }}>{errorMessage}</div>}
        </div>

        <div className='text-sm text-center my-2'>
          <label className='flex gap-2 justify-center text-[--themeColor]'>Resend Otp:
            <span className='font-semibold text-[--themeColor]' onClick={() => { }}>
              {countdown > 0 ? (` Retry in ${countdown} sec`) : <div className='cursor-pointer' onClick={handleResendOtp}>Resend</div>}
            </span>
          </label>
        </div>

        <div className='flex items-center justify-center '>
          <Button buttonTitle='Verify OTP' onClick={() => { handleVerifyOtp() }} background={'bg-[#10b981]'} />
        </div>
      </div>}
    </>
  )
}

export default VerifyLoginOtp
