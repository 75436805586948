import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import Datetime from 'react-datetime';
import { Drawer, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createOffer, getOffers, selectOfferError, toogleOfferStatus } from '../../../../redux/Slices/offerSlice';
import Button from '../../../atoms/Button/Button';
import Input from '../../../atoms/Input/Input';
import SelectInput from '../../../atoms/SelectInput/SelectInput';
import ErrorText from '../../../atoms/ErrorText/ErrorText';
import "react-datetime/css/react-datetime.css";
import './CouponDrawer.scss'

const CouponDrawer = (props) => {
    const { showCouponDrawer, onPressCancel, setLoading, setShowCouponDrawer, errorMessage, setErrorMessage, editMode, offerToEdit } = props
    const [code, setCode] = useState('');
    const [offerText, setOfferText] = useState('');
    const [discountType, setDiscountType] = useState('');
    const [terms, setTerms] = useState('');
    const [discountAmt, setDiscountAmt] = useState('');
    const [minOrderAmt, setMinOrderAmt] = useState('');
    const [maxDiscountAmt, setMaxDiscountAmt] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const dispatch = useDispatch();
    const couponError = useSelector(selectOfferError);

    // Handle to Edit the field
    useEffect(() => {
        if (editMode && offerToEdit) {
            setCode(offerToEdit.code);
            setOfferText(offerToEdit.offer_text);
            setDiscountType(offerToEdit.discount_type);
            setTerms(offerToEdit.terms);
            setDiscountAmt(offerToEdit.discount_amt);
            setMinOrderAmt(offerToEdit.min_order_amt);
            setMaxDiscountAmt(offerToEdit.max_discount_amt);
            setStartDate(offerToEdit.start_date ? moment(offerToEdit.start_date) : null);
            setEndDate(offerToEdit.end_date ? moment(offerToEdit.end_date) : null);
        }
    }, [editMode, offerToEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'code':
                setCode(value);
                break;
            case 'offer_text':
                setOfferText(value);
                break;
            case 'terms':
                setTerms(value);
                break;
            case 'discount_amt':
                setDiscountAmt(value);
                break;
            case 'min_order_amt':
                setMinOrderAmt(value);
                break;
            case 'max_discount_amt':
                setMaxDiscountAmt(value);
                break;
            default:
                break;
        }
    };

    const handleSelectInput = (selectedOption) => {
        const value = selectedOption.value;
        if (['flat', 'percentage'].includes(value)) {
            setDiscountType(value);
        } else {
            message.error("Invalid discount type selected.");
        }
    }

    const handleDateChange = (date, type) => {
        if (type === 'start') {
            setStartDate(date);
        } else if (type === 'end') {
            setEndDate(date);
        }
    };

    // Validate the fields
    const validateFields = () => {
        if (!code || !offerText || !discountType || !terms || !discountAmt || !startDate || !endDate) {
            setErrorMessage(true);
            return false;
        }

        if (discountType === 'flat' && (!minOrderAmt || parseFloat(discountAmt) >= parseFloat(minOrderAmt))) {
            setErrorMessage("For 'flat' discount type, min_order_amt is required and discount_amt must be less than min_order_amt.");
            return false;
        } else if (discountType === 'percentage' && (!maxDiscountAmt || parseFloat(discountAmt) < 0 || parseFloat(discountAmt) > 100)) {
            setErrorMessage("For 'percentage' discount type, max_discount_amt is required and discount_amt must be between 0 and 100.");
            return false;
        }

        return true;
    };

    // Handle to Submit Coupon 
    const onSubmit = async () => {
        setErrorMessage(false);
        setLoading(true)

        if (!validateFields()) {
            setLoading(false);
            return;
        }
        const couponData = {
            code,
            offer_text: offerText,
            discount_type: discountType,
            terms,
            discount_amt: parseFloat(discountAmt),
            min_order_amt: discountType === 'flat' ? (minOrderAmt ? parseFloat(minOrderAmt) : null) : null,
            max_discount_amt: discountType === 'percentage' ? (maxDiscountAmt ? parseFloat(maxDiscountAmt) : null) : null,
            start_date: startDate ? moment(startDate).format('YYYY-MM-DD HH:mm:ss') : null,
            end_date: endDate ? moment(endDate).format('YYYY-MM-DD HH:mm:ss') : null,
        };

        if (editMode && offerToEdit && offerToEdit.id) {
            couponData.id = offerToEdit.id; // Include offer_id when editing
        } else if (editMode) {
            setErrorMessage("Offer ID is required for editing.");
            setLoading(false);
            return;
        }

        // Remove max_discount_amt and min_order_amt if discount_type is flat or percentage
        if (discountType === 'flat') {
            delete couponData.max_discount_amt;
        } else if (discountType === 'percentage') {
            delete couponData.min_order_amt;
        }

        // Dispatch createOffer and getOffer
        try {
            if (editMode) {
                await dispatch(toogleOfferStatus(couponData)).unwrap();
            } else {
                await dispatch(createOffer(couponData)).unwrap();
            }
            await dispatch(getOffers()).unwrap();
            setShowCouponDrawer(false);
            setLoading(false)
            setCode('');
            setOfferText('');
            setDiscountType('');
            setTerms('');
            setDiscountAmt('');
            setMinOrderAmt('');
            setMaxDiscountAmt('');
            setStartDate(null);
            setEndDate(null);
        } catch (error) {
            console.error('Error creating offer:', error);
        }
        setLoading(false)

    };

    return (
        <Drawer
            open={showCouponDrawer}
            onClose={onPressCancel}
            placement="right"
            className='drawer'
            title={editMode ? "Edit Coupon" : "Add Coupon"}
        >
            <div className='drawer-container'>
                <div>
                    <Input
                        label="Coupon code *"
                        placeholder="Enter Coupon code"
                        type="any"
                        id="code"
                        name="code"
                        value={code}
                        onChange={handleInputChange}
                        disabled={editMode} />
                    {errorMessage && <ErrorText show={!code} message="This is a required field." />}
                    {/* {couponError && <ErrorText show={!code} message={couponError.error.message === '"code" is required' && couponError.error.message} />} */}
                </div>
                <div>
                    <Input
                        label="Offer Text *"
                        placeholder="Enter Offer text"
                        type="any"
                        id="offer_text"
                        name="offer_text"
                        value={offerText}
                        onChange={handleInputChange}
                        disabled={editMode}
                    />
                    {errorMessage && <ErrorText show={!offerText} message="This is a required field." />}
                    {/* {couponError && <ErrorText show={!offerText} message={couponError.error.message} />} */}
                </div>

                <div>
                    <SelectInput
                        label="Discount Type *"
                        placeholder="Enter Discount Type"
                        selected={discountType}
                        type="discount"
                        id="discount_type"
                        name="discount_type"
                        manualOptions={[]}
                        value={discountType}
                        onChange={(value) => { handleSelectInput(value) }}
                        disabled={editMode}
                    />
                    {errorMessage && <ErrorText show={!discountType} message="This is a required field." />}
                </div>

                <div>
                    <Input
                        label="Discount amt *"
                        placeholder="Enter Discount amt"
                        type="any"
                        id="discount_amt"
                        name="discount_amt"
                        value={discountAmt}
                        onChange={handleInputChange}
                        disabled={editMode}
                    />
                    {errorMessage && <ErrorText show={!discountAmt} message="This is a required field." />}
                </div>

                {discountType === 'flat' &&
                    <div>
                        <Input
                            label="Min. Order Amt *"
                            placeholder="Enter Min. Order Amt"
                            type="any"
                            id="min_order_amt"
                            name="min_order_amt"
                            value={minOrderAmt}
                            onChange={handleInputChange}
                            disabled={editMode}
                        />
                        {errorMessage && <ErrorText show={!minOrderAmt} message="This is a required field." />}
                    </div>
                }

                {discountType === 'percentage' &&
                    <div>
                        <Input
                            label="Max. Discount Amt *"
                            placeholder="Enter Max. Discount Amt"
                            type="any"
                            id="max_discount_amt"
                            name="max_discount_amt"
                            value={maxDiscountAmt}
                            onChange={handleInputChange}
                            disabled={editMode}
                        />
                        {errorMessage && <ErrorText show={!maxDiscountAmt} message="This is a required field." />}
                    </div>}

                <div>
                    <div className='form-label'>Start Date *</div>
                    <Datetime
                        value={startDate ? moment(startDate) : null}
                        onChange={(date) => handleDateChange(date, 'start')}
                        placeholder="Start Date"
                    />
                    {errorMessage && <ErrorText show={!startDate} message="This is a required field." />}
                    {/* {couponError && <ErrorText show={couponError} message={couponError.error.message} />} */}
                </div>

                <div>
                    <div className='form-label'>End Date *</div>
                    <Datetime
                        value={endDate ? moment(endDate) : null}
                        onChange={(date) => handleDateChange(date, 'end')} />
                    {errorMessage && <ErrorText show={!endDate} message="This is a required field." />}
                </div>

                <div>
                    <Input
                        label="Terms and condition *"
                        placeholder="Enter Terms and condition"
                        type="any"
                        id="terms"
                        name="terms"
                        value={terms}
                        onChange={handleInputChange}
                        disabled={editMode}
                    />
                    {errorMessage && <ErrorText show={!terms} message="This is a required field." />}
                </div>
                <div className='flex justify-end mt-[2rem]'>
                    <Button buttonTitle={editMode ? 'Update Coupon' : 'Add Coupon'} background='bg-[--themeColor]' onClick={onSubmit} />
                </div>
            </div>
        </Drawer>
    );
};

export default CouponDrawer;