import React, { useState } from 'react'
import { image } from '../../../../app/utils/common/image'
import Input from '../../../atoms/Input/Input'
import Button from '../../../atoms/Button/Button'
import Loader from '../../../atoms/Loader/Loader'
import SaveModal from '../../../atoms/save-modal/save-modal'

const CategoryContainer = (props) => {
    const { categories, handleRemoveCategory, handleInputChange, handleCategorySave, loading } = props
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    
    const confirmDelete = (index, categoryId) => {
        setDeleteIndex({ index, categoryId });
        setDeleteModal(true);
    };

    const handleDelete = () => {
        if (deleteIndex !== null) {
            handleRemoveCategory(deleteIndex.index, deleteIndex.categoryId);
            setDeleteModal(false);
            setDeleteIndex(null);
        }
    };
    return (
        <div className='card-border pb-[150px] overflow-auto'>
            <div className='flex justify-end py-[20px] pr-[30px]  [border-bottom:_1px_solid_#e2e9e9]'>
                <Button buttonTitle='Save' background={'bg-[#10b981]'} onClick={() => { handleCategorySave() }} />
            </div>
            {!loading ? <div className='overflow-auto  max-h-[700px]'>
                {categories.map((category, index) => {
                    return (
                        <> <div className='flex gap-8 items-center justify-between p-[2rem] [border-bottom:_1px_solid_#e2e9e9] ' key={index}>
                            <div className='flex items-center'>
                                <div className='text-2xl font-[600] text-[--themeColor]'>Category {index + 1}</div>
                                <div className='flex gap-10 ml-[5rem]'>
                                    <Input
                                        label="Category *"
                                        placeholder="Enter your category"
                                        type="text"
                                        id={`category-${index}`}
                                        name="category"
                                        value={category.name || ''}
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                        disabled={!category.isNew} // Disable input if the category is not new
                                    />
                                </div>
                            </div>
                            <img onClick={() => { confirmDelete(index, category.id); }} className='cursor-pointer w-[20px]' src={image.IMG_RED_DELETE} />
                        </div>
                            {deleteModal && <SaveModal
                                text="Are you sure you want to delete this category?"
                                deleteModal={deleteModal}
                                setDeleteModal={setDeleteModal}
                                buttons={['Yes', 'No']}
                                onPressPositive={handleDelete}
                                onPressNegative={() => { setDeleteModal(false); setDeleteIndex(null); }}
                            />}

                        </>
                    )
                })}
            </div>
                :
                <div className='max-h-[450px] flex items-center justify-center h-[450px]'>
                    <Loader />
                </div>}


        </div>
    )
}

export default CategoryContainer
