import React from 'react';
import LogOutButton from '../../atoms/LogOutButton/LogOutButton';
import './Header.scss'
import { image } from '../../../app/utils/common/image';

const Header = () => {
    return (
        <>
            <div className='header-container'>
                <div className='header-flex'>
                    {/* <VisueatsIconLogo /> */}
                    <div className='flex items-center gap-[10px]'>
                        <img src={image.IMG_LOGO} alt='logo' className='w-[24px] h-[24px]' />
                        <div className='logo-text'>AdSimpl</div>
                    </div>
                    <div className="option-container">
                        {/* <ProfileImage /> */}
                        {/* <div className='get-help' onClick={() => { }}>
                            <div className='get-help-icon' ><CiCircleQuestion /></div>
                            <div className="get-help-header"> Need Help? </div>
                        </div> */}
                        {/* <div className='border'></div> */}
                        <LogOutButton />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
