import React from 'react';
import Loader from '../../../atoms/Loader/Loader';
import './UsersContainer.scss'

const UsersContainer = ({ usersData, searchString, loading }) => {
    return (
        <>
            <div>
                <table className='table-default w-full'>
                    <thead>
                        <tr>
                            <th className='text-start'>Name</th>
                            <th>Shop Name</th>
                            <th>Mobile</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ?
                            <tr>
                                <td colSpan="3"> <Loader height='h-[20rem]' />
                                </td>
                            </tr>
                            : <>
                                {!searchString && usersData.map((user) => (
                                    <tr key={user.id}>
                                        <td className='text-start capitalize'>{user.name}</td>
                                        <td>{user.shop_name}</td>
                                        <td>{user.phone_no}</td>
                                    </tr>
                                ))}
                                {searchString && usersData.length === 0 && (
                                    <tr className='h-[250px] flex items-center justify-end ml-[14px]'>
                                        Try searching for something else!
                                    </tr>
                                )}
                                {searchString && usersData.length !== 0 && usersData.map((user) => (
                                    <tr key={user.id}>
                                        <td className='text-start capitalize'>{user.name}</td>
                                        <td>{user.shop_name}</td>
                                        <td>{user.phone_no}</td>
                                    </tr>
                                ))}
                            </>}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default UsersContainer
