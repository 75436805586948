import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCategory, deleteCategory, getCategory, selectAllCategories, selectCategoryStatus } from '../../../redux/Slices/addCategorySlice'
import PageContainerHeader from '../PageContainerHeader/PageContainerHeader'
import CategoryContainer from './CategoryContainer/CategoryContainer'
import EmptyTemplate from '../../atoms/EmptyTemplate/EmptyTemplate'

const CategoryScreen = () => {
    const [categories, setCategories] = useState([{}]);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch()
    const allCategories = useSelector(selectAllCategories);
    const categoryStatus = useSelector(selectCategoryStatus);

    useEffect(() => {
        if (categoryStatus === 'loading') {
            setLoading(true);
        } else if (categoryStatus === 'succeeded') {
            setLoading(false);
        }
    }, [categoryStatus]);

    useEffect(() => {
        dispatch(getCategory())
    }, [dispatch])

    // Fetched categories
    useEffect(() => {
        setLoading(true)
        if (allCategories && allCategories.length > 0) {
            setCategories(allCategories.map(category => ({ ...category, isNew: false })));
        }
        setLoading(false)
    }, [allCategories])

    const handleAddCategory = () => {
        setCategories([{ name: '', isNew: true }, ...categories]);
    };

    // Remove Category
    const handleRemoveCategory = (index, categoryId) => {
        setLoading(true);
        if (!categoryId && !categories[index]?.name) {
            const temp = [...categories];
            temp.splice(index, 1);
            setCategories(temp);
            setLoading(false);
        } else if (categoryId) {
            dispatch(deleteCategory(categoryId)).unwrap()
                .then(() => {
                    dispatch(getCategory()).unwrap();
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    // Input change
    const handleInputChange = (index, value) => {
        const temp = [...categories];
        temp[index].name = value;
        setCategories(temp);
    }

    // Save Category
    const handleCategorySave = async () => {
        setLoading(true);
        const newCategories = categories.filter(cat => cat.isNew && cat.name);

        try {
            for (const category of newCategories) {
                await dispatch(addCategory({ name: category.name })).unwrap();
            }
            await dispatch(getCategory()).unwrap();
        } catch (error) {
            console.error('Error saving categories:', error);
        }
        setLoading(false);
    };

    return (
        <div>
            <PageContainerHeader title='Category' button buttonTitle='Add Category' onclickButton={handleAddCategory} />
            {categories.length === 0 ?
                (<EmptyTemplate line1='Oops! Something got scrambled.' line2='Add a Category' />) :
                <CategoryContainer categories={categories}
                    handleRemoveCategory={handleRemoveCategory}
                    handleInputChange={handleInputChange}
                    handleCategorySave={handleCategorySave}
                    loading={loading} />
            }
        </div>
    )
}

export default CategoryScreen
