import React from 'react';
import Loader from '../../../atoms/Loader/Loader';

const ShopsContainer = ({ stores, loading }) => {
    return (
        <>
            <div className='h-[740px] overflow-auto'>
                <table className='table-default w-full'>
                    <thead>
                        <tr>
                            <th className='text-start'>Shop Name</th>
                            <th>Shop Owner's Name</th>
                            <th>Shop's address</th>
                            <th>Phone Number</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ?
                            <tr>
                                <td colSpan="3"> <Loader height='h-[20rem]' /></td>
                            </tr>
                            :
                            <div className='p-[12px] contents '>
                                {stores.map((store, index) => {
                                    return (
                                        <tr className='m-[12px] [border-bottom:_.8px_solid_#e2e9e9]'>
                                            <td className='text-start capitalize'>{store.shop_name}</td>
                                            <td>{store.owner_name}</td>
                                            <td>{store.address}, {store.city}, {store.state}</td>
                                            <td>{store.owner_phone_no}</td>
                                            <td className='flex justify-center items-center' >
                                                <div className={`Success-bg ${store.display_status === 'PENDING' ? 'bg-[#f59e0b]' : store.display_status === 'COMPLETED' ? 'bg-[#10b981]' : 'bg-[#8b0000]'}`}> {store.display_status}</div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </div>}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ShopsContainer
