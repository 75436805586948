import React from 'react'
import { Modal } from 'antd'
import { image } from '../../../../app/utils/common/image'

const OpsDisplayModal = ({ openDisplayModal, setOpenDisplayModal, store }) => {
    return (
        <div>
            <Modal open={openDisplayModal}
                onCancel={() => { setOpenDisplayModal(false) }}
            >
                <div className='save-container'>
                    <div className="ad-header">
                        <div className='text-[--themeColor] text-[18px] font-bold ml-[38%]'>Display Details</div>
                        <button className="btn-modal close-button" aria-label="Close" onClick={() => setOpenDisplayModal(false)}>
                            <img src={image.IMG_CLOSE} className="image_close" />
                        </button>
                    </div>
                    <div className="save-main-container gap-4">
                        <div className='flex gap-4 text-[18px]'>
                            <div>Display UserName:</div>
                            <div className='font-bold '>{store.id}</div>
                        </div>
                        <div className='flex gap-4 text-[18px]'>
                            <div>Display Password:</div>
                            <div className='font-bold '>{store.password ? store.password : 'Pending'}</div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default OpsDisplayModal