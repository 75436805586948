import React from 'react'
import Header from '../Header/Header'
import SideNavbar from '../SideNavBar/SideNavBar'
import OpsTeamScreen from '../../molecules/OpsTeam/OpsTeamScreen'

const AddOpsTeam = () => {
    return (
        <div>
            <Header />
            <div className='pt-[58px] flex'>
                <SideNavbar />
                <div className='w-[100%] p-6'>
                    <OpsTeamScreen />
                </div>
            </div>
        </div>
    )
}

export default AddOpsTeam
