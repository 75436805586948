import React from 'react'
import Header from '../Header/Header'
import SideNavbar from '../SideNavBar/SideNavBar'
import AllTransactions from '../../molecules/AllTransactions/AllTransactions'

const Transactions = () => {
    return (
        <div>
            <Header />
            <div className='pt-[58px] flex'>
                <SideNavbar />
                <div className='w-[100%] p-6'>
                    <AllTransactions />
                </div>
            </div>
        </div>
    )
}

export default Transactions
