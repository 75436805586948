import React from "react";
import ReactImageUploading from "react-images-uploading";
import { FiUpload } from 'react-icons/fi'
import './AddDeleteAd.scss'


const AddDeleteAd = ({ handleImg, uploadButton, handleRemoveTemplate, handleUploadImg }) => {

    return (
        <div className="relative">
            <div className="edit-promotion-container" >
                <div className="">
                    {uploadButton && <div className="edit-reservation-button" >
                        <button className='btns edit-reservation-wrapper'>
                            <ReactImageUploading
                                multiple={false}
                                value={[]}
                                onChange={handleUploadImg}
                                allowNonImageType={true}
                                // onError={(e) => { setErrorModal(true) }}
                                acceptType={['jpeg', 'jpg', 'png', 'gif', 'mov', 'mp4', 'heic']}
                            >
                                {({ onImageUpload }) => (
                                    <div onClick={onImageUpload} style={{ display: 'flex' }}>
                                        <div className="upload-icon" style={{ marginRight: '13%' }} >
                                            <FiUpload />
                                        </div>
                                        <div className="contact" style={{ color: "--themeColor" }}>Upload</div>
                                    </div>
                                )}
                            </ReactImageUploading>

                        </button>
                    </div>}

                    <div className="edit-reservation-button">
                        <button className='btns edit-reservation-wrapper' onClick={handleRemoveTemplate}>
                            <div className="edit-reservation-icon">
                                {/* <img src={image.IMG_RED_DELETE} className="contact-icon" />  */}
                            </div>
                            <div className="contact" style={{ color: "#EF4444" }} >Delete</div>
                        </button>
                    </div>

                </div>
            </div></div>
    )
}

export default AddDeleteAd