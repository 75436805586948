import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import { Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addStoreAd, getStoresAd, selectStoreError } from '../../../../redux/Slices/addStoreSlice';
import Input from '../../../atoms/Input/Input';
import Button from '../../../atoms/Button/Button';
import ErrorText from '../../../atoms/ErrorText/ErrorText';
import { Autocomplete, GoogleMap, Marker } from '@react-google-maps/api';

const AssignStoreDrawer = (props) => {
    const { showAssignDrawer, setShowAssignDrawer, opsUsers, setLoading, errorMessage, setErrorMessage } = props;
    const [shopName, setShopName] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [address, setAddress] = useState('');
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');
    const [city, setCity] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);
    const [selectedPosition, setSelectedPosition] = useState({ lat: 21.181119927085486, lng: 72.79392214369257 });
    const dispatch = useDispatch();
    const OpsError = useSelector(selectStoreError);

    const handlePhoneChange = (event) => {
        if (event.target.value.length <= 10) {
            setContactNo(event.target.value);
            setErrorMessage('');
        }
    };


    const onPressCancelDrawer = () => {
        setShowAssignDrawer(false)
        setErrorMessage('');
        setSelectedUser(null);
        setShopName('');
        setAddress('');
        setState('');
        setPincode('');
        setCity('');
        setOwnerName('');
        setContactNo('');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'shopName':
                setShopName(value);
                break;
            case 'ownerName':
                setOwnerName(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'state':
                setState(value);
                break;
            case 'pincode':
                setPincode(value);
                break;
            case 'city':
                setCity(value);
                break;
            default:
                break;
        }
    };

    const handleUserChange = (selectedOption) => {
        setSelectedUser(selectedOption ? selectedOption?.value : null);
    };

    const handleMapClick = useCallback((event) => {
        if (event.latLng) {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: { lat, lng } }, (results, status) => {
                if (status === 'OK' && results && results[0]) {
                    const formattedAddress = results[0].formatted_address;
                    const addressComponents = results[0].address_components;

                    const extractedCity = addressComponents.find((component) => component.types.includes('locality'))?.long_name || '';
                    const extractedCountry = addressComponents.find((component) => component.types.includes('country'))?.long_name || '';
                    const extractedState = addressComponents.find((component) => component.types.includes('administrative_area_level_1'))?.long_name || '';
                    const extractedPincode = addressComponents.find((component) => component.types.includes('postal_code'))?.long_name || '';
                    setAddress(formattedAddress);
                    setCity(extractedCity);
                    setState(extractedState);
                    // setCountry(extractedCountry);
                    setPincode(extractedPincode);
                    setSelectedPosition({ lat, lng });
                } else {
                    setAddress('Location not found');
                }
            });
        }
    }, []);

    const onLoadAutocomplete = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const lat = place.geometry.location?.lat();
                const lng = place.geometry.location?.lng();
                const formattedAddress = place.formatted_address;
                setAddress(formattedAddress);

                const addressComponents = place.address_components;
                const extractedCity = addressComponents.find((component) => component.types.includes('locality'))?.long_name || '';
                const extractedCountry = addressComponents.find((component) => component.types.includes('country'))?.long_name || '';
                const extractedState = addressComponents.find((component) => component.types.includes('administrative_area_level_1'))?.long_name || '';
                const extractedPincode = addressComponents.find((component) => component.types.includes('postal_code'))?.long_name || '';

                setCity(extractedCity);
                // setCountry(extractedCountry);
                setState(extractedState)
                setPincode(extractedPincode);
                setSelectedPosition({ lat, lng });
            }
        }
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const validateFields = () => {
        if (!shopName || !ownerName || !contactNo || !address || !state || !pincode || !city || !selectedUser) {
            setErrorMessage(true);
            return false;
        }
        return true;
    };

    const onSubmit = async () => {
        setErrorMessage(false);
        setLoading(true);

        if (!validateFields()) {
            setLoading(false);
            return;
        }

        const shopData = {
            shop_name: shopName,
            address,
            city,
            country: "India",
            state,
            pin_code: pincode,
            latitude: String(selectedPosition.lat),
            longitude: String(selectedPosition.lng),
            owner_name: ownerName,
            country_code: "+91",
            phone_no: contactNo,
            user_id: selectedUser ? Number(selectedUser) : null,
        };

        try {
            onPressCancelDrawer();
            await dispatch(addStoreAd(shopData)).unwrap(); // Dispatch the Add Stores Ads action
            await dispatch(getStoresAd()).unwrap(); // Dispatch the Get Stores Ads action
        } catch (error) {
            console.error('Error assigning store:', error);
        }
        setLoading(false);
    };

    return (
        <div>
            <Drawer
                open={showAssignDrawer}
                onClose={() => { onPressCancelDrawer() }}
                placement="right"
                className='drawer'
                title="Assign Store"
            >
                <div className='drawer-container'>
                    <div>
                        <div className='form-label'>User *</div>
                        <Select
                            placeholder="Select user"
                            value={opsUsers?.find(user => user?.id === selectedUser) ? { label: opsUsers?.find(user => user?.id === selectedUser).name, value: selectedUser } : null}
                            options={opsUsers.map(user => ({ label: user.name, value: user.id }))}
                            onChange={handleUserChange}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderRadius: '0.375rem',
                                    boxShadow: 'none',
                                    background: state.isFocused ? 'linear-gradient(white, white) padding-box,linear-gradient(95.39deg, #B2D236 9.4%, #2BB24B 51.68%, #01A8AC 96.92%) border-box' : '',
                                    border: state.isFocused ? '1.5px solid #0c4a6e' : '',
                                    "&:hover": {
                                        background: props.disabled ? '#d1d5db' : 'linear-gradient(white, white) padding-box,linear-gradient(95.39deg, #B2D236 9.4%, #2BB24B 51.68%, #01A8AC 96.92%) border-box',
                                        border: '2px solid #0c4a6e'
                                    },
                                    minHeight: '44px',
                                    cursor: props.disabled ? 'no-drop' : 'pointer',
                                }),
                            }}
                        />
                        {errorMessage && <ErrorText show={!selectedUser} message="This is a required field." />}
                    </div>
                    <div>
                        <Input
                            label="Shop Name *"
                            placeholder="Enter Shop Name"
                            type="text"
                            id="shopName"
                            name="shopName"
                            value={shopName}
                            onChange={handleInputChange}
                        />
                        {errorMessage && <ErrorText show={!shopName} message="This is a required field." />}
                    </div>
                    <div>
                        <Input
                            label="Shop Owner's Name *"
                            placeholder="Enter Shop Owner's Name"
                            type="text"
                            id="ownerName"
                            name="ownerName"
                            value={ownerName}
                            onChange={handleInputChange}
                        />
                        {errorMessage && <ErrorText show={!ownerName} message="This is a required field." />}
                    </div>
                    <div>
                        <Input
                            label="Shop Owner's Phone number  *"
                            placeholder="Enter Owner's Phone number"
                            type="number"
                            id="contactNo"
                            name="contactNo"
                            value={contactNo}
                            onChange={handlePhoneChange}
                        />
                        {errorMessage && <ErrorText show={!contactNo} message="This is a required field." />}
                    </div>
                    <div>
                        <Autocomplete
                            onLoad={onLoadAutocomplete}
                            onPlaceChanged={onPlaceChanged}
                        >
                            <Input
                                label='Address *'
                                placeholder="Enter Address"
                                type="text"
                                value={address}
                                onChange={handleAddressChange}
                                className='mb-2'
                            />
                        </Autocomplete>
                        <GoogleMap
                            mapContainerStyle={{ height: "300px", width: "100%", borderRadius: "12px" }}
                            center={selectedPosition}
                            zoom={10}
                            onClick={handleMapClick}
                            options={{
                                zoomControl: true,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false,
                            }}
                        >
                            <Marker position={selectedPosition} />
                        </GoogleMap>
                        {errorMessage && <ErrorText show={!address} message="This is a required field." />}
                    </div>
                    <div>
                        <Input
                            label="State *"
                            placeholder="Enter State"
                            type="text"
                            id="state"
                            name="state"
                            value={state}
                            onChange={handleInputChange}
                        />
                        {errorMessage && <ErrorText show={!state} message="This is a required field." />}
                    </div>

                    <div>
                        <Input
                            label="City *"
                            placeholder="Enter City"
                            type="text"
                            id="city"
                            name="city"
                            value={city}
                            onChange={handleInputChange}
                        />
                        {errorMessage && <ErrorText show={!city} message="This is a required field." />}
                    </div>
                    <div>
                        <Input
                            label="Pincode *"
                            placeholder="Enter Pincode"
                            type="text"
                            id="pincode"
                            name="pincode"
                            value={pincode}
                            onChange={handleInputChange}
                        />
                        {errorMessage && <ErrorText show={!pincode} message="This is a required field." />}
                    </div>
                    {OpsError && <ErrorText show message={OpsError?.error?.message} />}
                    <div className='flex justify-end mt-[2rem]'>
                        <Button buttonTitle='Assign Store' background='bg-[--themeColor]' onClick={onSubmit} />
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default AssignStoreDrawer;