import React from 'react'
import Header from '../Header/Header'
import SideNavbar from '../SideNavBar/SideNavBar'
import AgencyContainer from '../../molecules/OpsTeam/AgencyContainer/AgencyContainer'

const AllAgency = () => {
    return (
        <div>
            <Header />
            <div className='pt-[58px] flex'>
                <SideNavbar />
                <div className='w-[100%] p-6'>
                    <AgencyContainer />
                </div>
            </div>
        </div>
    )
}

export default AllAgency