import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAgencyRequest, selectAgenciesPagination, selectAgenciesStatus, selectAllAgencies } from '../../../../redux/Slices/agencySlice';
import Pagination from '../../../atoms/Pagination/Pagination';
import PageContainerHeader from '../../PageContainerHeader/PageContainerHeader';
import AllTransactionsContainer from '../../AllTransactions/AllTransactionContainer/AllTransactionContainer';
import Loader from '../../../atoms/Loader/Loader';
import AllAgecniesContainer from '../../AllAgencies/AllAgenciesContainer/AllAgenciesContainer';

const AllAgencies = () => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);

    const dispatch = useDispatch();
    const agencies = useSelector(selectAllAgencies);
    const pagination = useSelector(selectAgenciesPagination);
    const agencyStatus = useSelector(selectAgenciesStatus)

    useEffect(() => {
        dispatch(getAgencyRequest({ page: currentPage, pageSize })); // Dispatch Get Agencies action
    }, [dispatch, currentPage, pageSize])

    // Transaction status loading state
    useEffect(() => {
        if (agencyStatus === 'loading') {
            setLoading(true);
        } else if (agencyStatus === 'succeeded') {
            setLoading(false);
        }
    }, [agencyStatus]);

    const pageNumbers = [];
    for (let i = 1; i <= pagination?.totalPages; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div>
            <PageContainerHeader title={'All Agency'} />
            {!loading ? <><AllAgecniesContainer agencies={agencies} setLoading={setLoading}/>
                <Pagination
                    pageNumbers={pageNumbers}
                    handlePageLink={handlePageChange}
                    totalProfile={pagination.totalData}
                    prevPage={() => handlePageChange(pagination.currentPage - 1)}
                    nextPage={() => handlePageChange(pagination.currentPage + 1)}
                    activePage={pagination.currentPage}
                /></> : <div className='h-[26rem] flex justify-center items-center'> <Loader /></div>}
        </div>
    )
}

export default AllAgencies
