import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BASE_URL } from "../../app/utils/apis/base";
import { ApiConstants } from "../../app/utils/apis";
import { apiClient } from "../../app/helper/axiosHelper";

// Async thunk for fetching transactions
export const getTransactions = createAsyncThunk(
    'transc/getTransactions',
    async ({ page = 1, pageSize = 10 }, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(`${BASE_URL}${ApiConstants.ALLTRANSACTIONS}`, {
                params: { page, pageSize }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Slice for transactions
const allTransactionsSlice = createSlice({
    name: 'transc',
    initialState: {
        transactions: [],
        status: 'idle',
        error: null,
        pagination: {
            totalData: 0,
            currentPage: 1,
            pageSize: 10,
            totalPages: 1
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTransactions.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getTransactions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.transactions = action.payload.data.transactions;
                state.pagination = action.payload.data.pagination;
            })
            .addCase(getTransactions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload.error.message || action.payload : 'An unknown error occurred';
            });
    }
});

// Selectors for accessing state
export const selectAllTransactions = (state) => state.transc.transactions;
export const selectTransactionPagination = (state) => state.transc.pagination;
export const selectTransactionStatus = (state) => state.transc.status;

export default allTransactionsSlice.reducer;