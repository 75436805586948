import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { FiCheck } from 'react-icons/fi';
import { ImCross } from 'react-icons/im';
import { updateAdStatus } from '../../../../redux/Slices/adsRequestSlice';
import { useDispatch } from 'react-redux';
import { image } from '../../../../app/utils/common/image';
import { IoEyeOutline } from "react-icons/io5";
import FULLSCREEN from '../../../../assets/images/svg/full-screen.svg'
import Button from '../../../atoms/Button/Button';
import Loader from '../../../atoms/Loader/Loader';
import RejectModal from '../../../atoms/reject-modal/reject-modal';
import AdStatus from '../../../atoms/reserv-status/ad-status';
import AdViewModal from './AdViewModal';
import './AdvertismentContainer.scss'

const AdvertismentContainer = ({ ads, loading, setLoading, searchString, sortBy }) => {
    const [fullScreen, setFullScreen] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [selectedAdId, setSelectedAdId] = useState(null);
    const [showStatus, setShowStatus] = useState(false)
    const [openViewModal, setOpenViewModal] = useState(false);
    const [selectedAdData, setSelectedAdData] = useState(null);
    const dispatch = useDispatch();

    // Open Full screen 
    const openFullscreen = (id) => {
        const elem = document.getElementById(`adImage_${id}`);
        if (elem) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
                setFullScreen(true);
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
                setFullScreen(true);
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
                setFullScreen(true);
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
                setFullScreen(true);
            }
        } else {
            console.error(`Element with id adImage_${id} not found`);
        }
    };

    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            setFullScreen(false);
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
            setFullScreen(false);
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
            setFullScreen(false);
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
            setFullScreen(false);
        }
    };

    const handleAcceptAds = (adId) => {
        dispatch(updateAdStatus({ adId, status: 'APPROVE' }));
        setShowStatus(false)
    }

    const handleRejectAds = (adId) => {
        setSelectedAdId(adId);
        setShowRejectModal(true);
        setShowStatus(false)
    }

    // Handle to Reject the Ads on Click of Save button
    const handleRejectedSaveBtn = () => {
        setLoading(true)
        if (selectedAdId && rejectReason) {
            dispatch(updateAdStatus({ adId: selectedAdId, status: 'REJECT', reject_reason: rejectReason }));
            setShowRejectModal(false);
            setRejectReason('');
            setSelectedAdId(null);
            setShowStatus(false)
        }
        setLoading(false)
    }

    const handleShowStatus = (index) => {
        setShowStatus(showStatus === index ? null : index);
    }

    const handleViewAd = (ad) => {
        setSelectedAdData(ad);
        setOpenViewModal(true);
    }


    const handleClose = () => {
        setShowStatus(false)
    }

    const filteredAds = ads.filter(ad => {
        if (sortBy === 'All') return true;
        return ad.status === sortBy;
    });

    return (
        <>
            <div>
                <table className='table-default w-full'>
                    <thead>
                        <tr>
                            <th className='text-start'>Date</th>
                            <th className='text-start'>Image</th>
                            <th className='text-start'>UserName</th>
                            <th className='text-start'>Phone Number</th>
                            <th className='text-start'>Order Number</th>
                            <th className='text-start'>Paid</th>
                            <th className='text-start'>Amount</th>
                            <th className='text-start'>Status</th>
                            <th className='text-start'>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ?
                            <tr>
                                <td colSpan="7"> <Loader height='h-[20rem]' />
                                </td>
                            </tr>
                            : <>
                                {!searchString && filteredAds.map((ad, index) => {
                                    const createdAt = new Date(ad.createdAt).toISOString().split('T')[0];
                                    return (
                                        <tr className='[border-bottom:_1px_solid_#e5e7eb] pb-[12px]' key={index}>
                                            <td>
                                                <div className='flex justify-start text-[1.2rem]'>
                                                    {createdAt}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='flex justify-center flex-col relative w-[5rem]'>
                                                    <img
                                                        onClick={() => fullScreen ? exitFullScreen() : openFullscreen(ad.id)}
                                                        className={`cursor-pointer z-[1] h-[20px] absolute right-[4px] bottom-[4px]`}
                                                        src={FULLSCREEN}
                                                        alt='fullScreenBtn'
                                                    />
                                                    {ad?.image_name && (
                                                        <div className='text-[20px] mb-[12px] text-center'>{ad?.image_name}</div>
                                                    )}
                                                    <img
                                                        id={`adImage_${ad.id}`}
                                                        className="rounded-[15px] h-[3rem] shadow-md border border-black w-[10rem]"
                                                        src={ad?.image === '' ? image.DefaultImage : ad?.image}
                                                        alt="profile"
                                                        onClick={() => fullScreen ? exitFullScreen() : openFullscreen(ad.id)}
                                                    />

                                                </div>
                                            </td>
                                            <td>
                                                <div className='flex justify-center text-[1.2rem]'>
                                                    {ad?.user_name}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='flex justify-center text-[1.2rem]'>
                                                    {ad?.phone_no}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='flex justify-center text-[1.2rem]'>
                                                    {ad?.order_id ? ad?.order_id : "Null"}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='flex justify-center font-[800]'>
                                                    {ad?.payment_status === 'SUCCESS' ? <div className='text-[--themeColor]'>Paid</div> : <div className='text-[#8b0000]'>Unpaid</div>}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='flex justify-center text-[1.2rem]'>
                                                    {ad?.cost}
                                                </div>
                                            </td>
                                            <td> <div className='flex items-center justify-center'>{ad.status === 'APPROVE' ? (
                                                <Button buttonTitle={'Accepted'} background='bg-[#228B22]' className='cursor-not-allowed' disabled onClick={() => { }} />
                                            ) : ad.status === 'REJECT' ? (
                                                <Button buttonTitle={'Rejected'} background='bg-[#8b0000]' disabled className='cursor-not-allowed' onClick={() => { }} />
                                            ) : (
                                                <>
                                                    <Button buttonTitle={'Pending'} background='bg-[#f59e0b]' onClick={() => { handleShowStatus(index) }} />
                                                    {showStatus === index && (
                                                        <OutsideClickHandler onOutsideClick={handleClose}>
                                                            <AdStatus handleRejectAds={() => handleRejectAds(ad.id)} handleAcceptAds={() => handleAcceptAds(ad.id)} ad={ad} />
                                                        </OutsideClickHandler>
                                                    )}
                                                </>
                                            )} </div>
                                            </td>
                                            <td>
                                                <div className='flex justify-center text-[1.2rem] cursor-pointer' onClick={() => handleViewAd(ad)}>
                                                    <IoEyeOutline />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                                {searchString && filteredAds.length === 0 && (
                                    <tr className='h-[250px]'>
                                        <td colSpan="7" className='table-center'> Try searching for something else!
                                        </td>
                                    </tr>
                                )}
                                {searchString && filteredAds.length !== 0 && filteredAds.map((ad, index) => (
                                    <tr className='[border-bottom:_1px_solid_#e5e7eb] pb-[12px]' key={index}>
                                        <td>
                                            <div className='flex justify-center flex-col relative'>
                                                <img
                                                    onClick={() => fullScreen ? exitFullScreen() : openFullscreen(ad.id)}
                                                    className={`cursor-pointer z-[1] h-[40px] absolute right-[20px] bottom-[23px]`}
                                                    src={FULLSCREEN}
                                                    alt='fullScreenBtn'
                                                />
                                                {ad?.image_name && (
                                                    <div className='ml-[15px] text-[20px] mb-[12px] text-center'>{ad?.image_name}</div>
                                                )}
                                                <img
                                                    id={`adImage_${ad.id}`}
                                                    className="rounded-[33px] h-[20rem] shadow-md border border-black"
                                                    src={ad?.image === '' ? image.DefaultImage : ad?.image}
                                                    alt="profile"
                                                    onClick={() => fullScreen ? exitFullScreen() : openFullscreen(ad.id)}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='flex justify-center text-[1.2rem]'>
                                                {ad?.name}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='flex justify-center text-[1.2rem]'>
                                                {ad.country_code}{ad?.phone_no}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='flex justify-center text-[1.2rem]'>
                                                {ad?.order_id ? ad?.order_id : "Null"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='flex justify-center font-[800]'>
                                                {ad?.payment_status === 'SUCCESS' ? <FiCheck size={24} /> : <ImCross />}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='flex justify-center text-[1.2rem]'>
                                                {ad?.cost}
                                            </div>
                                        </td>
                                        <td> <div className='flex items-center justify-center'>{ad.status === 'APPROVE' ? (
                                            <Button buttonTitle={'Accepted'} background='bg-[#228B22]' className='cursor-not-allowed' disabled onClick={() => { }} />
                                        ) : ad.status === 'REJECT' ? (
                                            <Button buttonTitle={'Rejected'} background='bg-[#8b0000]' disabled className='cursor-not-allowed' onClick={() => { }} />
                                        ) : (
                                            <>
                                                <Button buttonTitle={'Pending'} background='bg-[#f59e0b]' onClick={() => { handleShowStatus(index) }} />
                                                {showStatus === index && (
                                                    <OutsideClickHandler onOutsideClick={handleClose}>
                                                        <AdStatus handleRejectAds={() => handleRejectAds(ad.id)} handleAcceptAds={() => handleAcceptAds(ad.id)} ad={ad} />
                                                    </OutsideClickHandler>
                                                )}
                                            </>
                                        )} </div>
                                        </td>
                                        <td>
                                            <div className='flex justify-center text-[1.2rem] cursor-pointer' onClick={() => handleViewAd(ad)}>
                                                <IoEyeOutline />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </>

                        }
                    </tbody>
                </table>
                <RejectModal showRejectModal={showRejectModal} setShowRejectModal={setShowRejectModal} handleRejectedSaveBtn={handleRejectedSaveBtn} rejectReason={rejectReason} setRejectReason={setRejectReason} />
                {openViewModal && selectedAdData && <AdViewModal openViewModal={openViewModal} setOpenViewModal={setOpenViewModal} ad={selectedAdData} />}
            </div>
        </>
    );
};

export default AdvertismentContainer;