import React from 'react'
import './reserv-status.scss'

const AdStatus = (props) => {
    const { handleAcceptAds, handleRejectAds } = props;
    return (
        <div style={{ position: "relative" }}>
            <div className="reserv-status-container">
                <div className="flex flex-col gap-2">
                    <div className="edit-reservation-button" style={{ cursor: "auto" }} >
                        <button className='btns edit-reservation-wrapper' style={{ cursor: "auto" }} onClick={handleAcceptAds}>
                            <div className="status-btn Accepted-bg status-btn-mobile" >
                                <span>Accepted</span>
                            </div>
                        </button>
                    </div>
                    <div className="edit-reservation-button" style={{ cursor: "auto" }}>
                        <button className='btns edit-reservation-wrapper' style={{ cursor: "auto" }} onClick={handleRejectAds}>
                            <div className="status-btn Rejected-bg  status-btn-mobile">
                                <span>Rejected</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AdStatus