import React, { useState } from 'react'
import SaveModal from '../../../atoms/save-modal/save-modal';

const ReportedAdsContainer = () => {
    const [removeModal, setRemoveModal] = useState(false)
    return (
        <>
            <div className='h-[740px] overflow-auto'>
                <table className='table-default w-full'>
                    <thead>
                        <tr>
                            <th className='text-start'>Shop Name</th>
                            <th>Shop Owner's Name</th>
                            <th>Phone Number</th>
                            <th>Reason</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        <div className='p-[12px] contents '>
                            <tr className='m-[12px] [border-bottom:_.8px_solid_#e2e9e9]'>
                                <td className='text-start capitalize'>audhjsad</td>
                                <td>fgdfsg</td>
                                <td>596234823</td>
                                <td>fgdfsgfdgt eirtuerkfjertyiewjsfvkn</td>
                                <td>
                                    <div className='flex items-center justify-center' onClick={() => setRemoveModal(true)}>
                                        <div className={`Success-bg cursor-pointer bg-[#ef4444]`}>Remove</div>
                                    </div>
                                </td>
                            </tr>

                        </div>
                    </tbody>
                    {removeModal && <SaveModal
                        text="Are you sure you want to remove this ad?"
                        deleteModal={removeModal}
                        setDeleteModal={setRemoveModal}
                        buttons={['Yes', 'No']}
                        onPressPositive={() => { }}
                        onPressNegative={() => { setRemoveModal(false); }}
                    />}
                </table>
            </div>
        </>
    )
}

export default ReportedAdsContainer
