import React from 'react'
import PageContainerHeader from '../PageContainerHeader/PageContainerHeader'
import ReportedAdsContainer from './ReportedAdsContainer/ReportedAdsContainer'

const ReportedAdsList = () => {
    return (
        <div>
            <PageContainerHeader title='All Reported Ads' />
            <ReportedAdsContainer />
        </div>
    )
}

export default ReportedAdsList
