import AppRoutes from './app/routes/AppRoutes';
import './App.css';
import { useLoadScript } from '@react-google-maps/api';

function App() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDrb_5q1m_fYiBjtx53rYSZskDa6Ns5JNw',
    libraries: ['places'],
  });
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;