import React from 'react';
import './Input.scss'

const Input = (props) => {
    const { label, placeholder, type, id, name, value, onChange, className, classNameInput, disabled } = props;

    return (
        <div className={className}>
            <div className="search_bar">
                <div className='form-label'>{label}</div>
                <input
                    className={`${classNameInput} search-input`}
                    placeholder={placeholder}
                    name={name}
                    type={type}
                    id={id}
                    // disabled={name === 'url' || name === "user_email" || name === "calendar" || name === 'guest' ? true : false}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    onWheel={(e) => e.target.blur()}
                />
            </div>
        </div>
    )
}

export default Input
